import {Dialog, DialogContent} from "@mui/material";
import * as React from "react";
import IconButton from "@mui/material/IconButton";
import CloseIcon from '@mui/icons-material/Close';
import Typography from "@mui/material/Typography";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import {Logo} from "../../nav/Logo.js";

export const PrivacyPolicyDialog = ({open, onClose}) => {
    const getBulletPoints = (items) => {
        return items.map((item, idx) => <Typography component="div" sx={{display: 'flex', alignItems: 'center'}}
                                                    gutterBottom={idx === items.length - 1}>
            <FiberManualRecordIcon color="inherit" sx={{fontSize: '8px', marginRight: '8px', color: 'black'}}/>
            <span>{item}</span>
        </Typography>)
    }

    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            xs={{justifyContent: 'center'}}
            fullWidth={true}
            maxWidth="md"
        >
            <DialogContent dividers sx={{height: '90vh', overflow: 'auto', padding: {sm: '2rem', md: '3rem'}}}>
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{float: 'right'}}
                >
                    <CloseIcon/>
                </IconButton>
                <Logo isLink={false} styles={{margin: 'auto'}}/>
                <Typography variant="h4" sx={{textAlign: 'center', marginTop: 2}} gutterBottom>
                    Applicant and Candidate Privacy Policy
                </Typography>

                <Typography variant="body1" gutterBottom>
                    This policy explains:
                </Typography>
                {getBulletPoints([
                    'What information we collect during our application and recruitment process and why we collect it;',
                    'How we use that information;',
                    'How to access and update that information.'
                ])}
                <Typography variant="body1" paragraph>
                    Your use of Meaningful Careers' services is governed by any applicable terms and our general Privacy
                    Policy.
                </Typography>

                <Typography variant="h5" gutterBottom>
                    Types of information we collect
                </Typography>
                <Typography variant="body1" gutterBottom>
                    This policy covers the information you share with us and/or which may be acquired or produced by
                    Meaningful Careers, its subsidiaries and its affiliates during the application or recruitment
                    process including:
                </Typography>
                {getBulletPoints([
                    "Your name, address, email address, telephone number and other contact information",
                    "Your resume or CV, cover letter, previous and/or relevant work experience or other experience, education, transcripts, or other information you provide to us in support of an application and/or the application and recruitment process",
                    "Information from interviews and phone-screenings you may have, if any",
                    "Details of the type of employment you are or may be looking for, current and/or desired salary and other terms relating to compensation and benefits packages, willingness to relocate, or other job preferences",
                    "Details of how you heard about the position you are applying for",
                    "Any sensitive and/or demographic information processed during the application or recruitment process such as gender, information about your citizenship and/or nationality, medical or health information and/or your racial or ethnic origin",
                    "Reference information and/or information received from background checks (where applicable), including information provided by third parties",
                    "Information relating to any previous applications you may have made to Meaningful Careers and/or any previous employment history with Meaningful Careers",
                    "Your information from publicly available sources, including online, that we believe is relevant to your application or a potential future application (e.g. your LinkedIn profile)",
                    "Information related to any assessment you may take as part of the interview screening process"
                ])}

                <Typography variant="h5" gutterBottom paddingTop='10px'>
                    How we use information we collect
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Your information will be used by Meaningful Careers for the purposes of carrying out its application
                    and
                    recruitment process which includes:
                </Typography>
                {getBulletPoints([
                    "Assessing your skills, qualifications and interests against our career opportunities",
                    "Verifying your information and carrying out reference checks and/or conducting background checks (where applicable) if you are offered a job",
                    "Communications with you about the recruitment process and/or your application(s), including, in appropriate cases, informing you of other potential career opportunities at Meaningful Careers",
                    "Creating and/or submitting reports as required under any local laws and/or regulations, where applicable",
                    "Where requested by you, assisting you with obtaining an immigration visa or work permit where required",
                    "Making improvements to Meaningful Careers’ application and/or recruitment process including improving diversity in recruitment practices",
                    "Complying with applicable laws, regulations, legal processes or enforceable governmental requests",
                    "Proactively conducting research about your educational and professional background and skills and contacting you if we think you would be suitable for a role with us",
                    "As part of our commitment to equal opportunity employment, we may process information regarding your membership in various organizations to support our diversity and inclusion efforts. This may include associating participant membership with sensitive and/or demographic information"
                ])}
                <Typography variant="body1" paragraph>
                    We will also use your information to protect the rights and property of Meaningful Careers, our
                    users,
                    applicants, candidates, employees or the public as required or permitted by law.
                </Typography>
                <Typography variant="body1" paragraph>
                    If you are offered and accept employment with Meaningful Careers, the information collected during
                    the
                    application and recruitment process will become part of your employment record.
                </Typography>

                <Typography variant="h5" gutterBottom>
                    Who may have access to your information
                </Typography>
                {getBulletPoints([
                    "Your information may be shared with our affiliates, subsidiaries or joint ventures in the US and in other jurisdictions, in relation to the purposes described above. If you have been referred for a job at Meaningful Careers by a current Meaningful Careers employee, with your consent, we may inform that employee about the progress of your application and let the Meaningful Careers employee know the outcome of the process. In some cases, if it is identified that you have attended the same university/school or shared the same previous employer during the same period as a current Meaningful Careers employee we may consult with that employee for feedback on you.",
                    "Meaningful Careers may also use service providers acting on Meaningful Careers’ behalf to perform some of the services described above including for the purposes of the verification / background checks. These service providers may be located outside the country in which you live or the country where the position you have applied for is located.",
                    "Meaningful Careers may sometimes be required to disclose your information to external third parties such as to local labour authorities, courts and tribunals, regulatory bodies and/or law enforcement agencies for the purpose of complying with applicable laws and regulations, or in response to legal process.",
                    "We will also share your personal information with other third parties if we have your consent (for example if you have given us permission to contact your referees), or to detect, prevent or otherwise address fraud, security or technical issues, or to protect against harm to the rights, property or safety of Meaningful Careers, our users, applicants, candidates, employees or the public or as otherwise required by law.",
                    "It is your responsibility to obtain consent from referees before providing their personal information to Meaningful Careers."
                ])}
                <Typography variant="body1" paragraph>
                    Meaningful Careers takes appropriate steps to protect information about you that is collected,
                    processed, and stored as part of the application and recruitment process.
                </Typography>

                <Typography variant="h5" gutterBottom>
                    Our retention of your information
                </Typography>
                <Typography variant="body1" paragraph>
                    If you apply for a job at Meaningful Careers and your application is unsuccessful (or you withdraw
                    from the
                    process or decline our offer), Meaningful Careers will retain your information for a period after
                    your
                    application. We retain this information for various reasons, including in case we face a legal
                    challenge in respect of a recruitment decision, to consider you for other current or future jobs at
                    Meaningful Careers and to help us better understand, analyze and improve our recruitment processes.
                </Typography>

                <Typography variant="h5" gutterBottom>
                    Your rights in respect of your information
                </Typography>
                <Typography variant="body1" paragraph>
                    In certain countries, you may have certain rights under data protection law. This may include the
                    right to request access or to update or review your information, request that it be deleted or
                    anonymized, or object to or restrict Meaningful Careers using it for certain purposes.
                </Typography>

                <Typography variant="h5" gutterBottom>
                    Changes to this Policy
                </Typography>
                <Typography variant="body1" paragraph>
                    We may change this policy from time to time. We will post any changes to this policy on this page.
                </Typography>
            </DialogContent>
        </Dialog>
    );
}