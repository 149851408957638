import {Box, TextField} from "@mui/material";
import React from "react";

export const SalaryRangeInputs = ({salary, setSalary, isHourly = false, error}) => {
    const handleMinChange = (event) => {
        const value = parseInt(event.target.value.replace(/\D/g, '').substring(0, 3));
        const newMin = isNaN(value) ? 0 : value;
        setSalary([newMin, salary[1]]);
    };

    const handleMaxChange = (event) => {
        const value = parseInt(event.target.value.replace(/\D/g, '').substring(0, 3));
        const newMax = isNaN(value) ? 0 : value;
        setSalary([salary[0], newMax]);
    };

    return (
        <Box display="flex" justifyContent="space-between" alignItems="center">
            <TextField
                variant="outlined"
                label={`Min Salary (in ${isHourly ? '$/hr' : '$k/year'})`}
                value={salary[0]}
                onChange={handleMinChange}
                sx={{width: '45%'}}
                error={error}
                helperText={error ? 'Please enter valid range' : null}
                required
            />
            <TextField
                variant="outlined"
                label={`Max Salary (in ${isHourly ? '$/hr' : '$k/year'})`}
                value={salary[1]}
                onChange={handleMaxChange}
                sx={{width: '45%'}}
                error={error}
                helperText={error ? 'Please enter valid range' : null}
                required
            />
        </Box>
    );
};
