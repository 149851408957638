import React, {useState} from "react"
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import {Link, useHistory} from 'react-router-dom';
import {useAuth} from "../../contexts/AuthContext";
import {RoutePath} from "../../utils/enums";
import {useSnackbar} from "../../contexts/SnackbarContext.js";
import {Google} from "@mui/icons-material";
import {analytics} from "../../firebase-config.js";
import {logEvent} from "firebase/analytics";
import {OrDivider} from "../universal/OrDivider.js";
import {userPalette} from "../../utils/theme.js";
import {PrivacyPolicyDialog} from "../universal/dialogs/PrivacyPolicyDialog.js";
import {logSentryException} from "../../utils/utils.js";

const SignUp = () => {
    const [email, setEmail] = useState()
    const [password, setPassword] = useState()
    const [passwordCheck, setPasswordCheck] = useState()
    const {signup, loginWithGoogle} = useAuth()
    const [loading, setLoading] = useState(false)
    const [isPrivacyDialogOpen, setIsPrivacyDialogOpen] = useState(false)
    const history = useHistory()
    const {openSnackbar} = useSnackbar()

    const handleSubmit = async (event) => {
        event.preventDefault();

        //const data = new FormData(event.currentTarget);
        if (password !== passwordCheck) {
            openSnackbar('Passwords do not match.', 'error');
            return;
        }

        try {
            setLoading(true)
            await signup(email, password)
            history.push(RoutePath.PROFILE_EDIT)
            logEvent(analytics, 'sign_up_submit', {
                method: 'Sign-Up Form Submission'
            });
        } catch (e) {
            openSnackbar('Failed to create an account. ' + e.toString().replace('FirebaseError: Firebase:', ''), 'error')
            logSentryException(e);
        }

        setLoading(false)
    };

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline/>
            <Box component="form" onSubmit={handleSubmit} noValidate
                 sx={{
                     mt: 8,
                     display: 'flex',
                     flexDirection: 'column',
                     alignItems: 'center',
                 }}
            >
                <Avatar sx={{m: 1, bgcolor: 'primary.main'}}>
                    <LockOutlinedIcon/>
                </Avatar>
                <Typography component="h1" variant="h4" sx={{ my: 1 }}>
                    Career Seeker Sign Up
                </Typography>
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    autoFocus
                    autoComplete="off"
                    onChange={(e) => setEmail(e.target.value)}
                />
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                    onChange={(e) => setPassword(e.target.value)}
                />
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="passwordCheck"
                    label="Confirm Password"
                    type="password"
                    id="passwordCheck"
                    autoComplete="current-password"
                    onChange={(e) => setPasswordCheck(e.target.value)}
                />

                <Typography sx={{
                    fontSize: '.6rem',
                    backgroundColor: '#F8F9FA',
                    color: '#3C4043',
                    borderRadius: '10px',
                    padding: '.5rem'
                }}>
                    Information collected and processed as part of your Meaningful Careers
                    profile, and any job applications you choose to submit is subject to
                    Meaningful Careers' <span onClick={() => setIsPrivacyDialogOpen(true)}
                                              style={{
                                                  textDecoration: 'underline',
                                                  color: userPalette.main,
                                                  cursor: 'pointer'
                                              }}>Applicant and Candidate Privacy Policy</span>.
                </Typography>

                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{my: 2, textTransform: 'capitalize', fontSize: '1rem'}}
                    disabled={loading}
                >
                    Sign Up
                </Button>
                <OrDivider/>
                <Button
                    fullWidth
                    variant="contained"
                    sx={{my: 2, textTransform: 'capitalize', fontSize: '1rem'}}
                    startIcon={<Google/>}
                    disabled={loading}
                    onClick={async () => {
                        await loginWithGoogle();
                        logEvent(analytics, 'google_sign_up_submit', {
                            method: 'Sign-Up Form Submission (with Google)'
                        });
                    }}
                >
                    Sign Up With Google
                </Button>
                <Link to={RoutePath.SIGN_IN} variant="body2" style={{textDecoration: 'none', fontSize: '1rem'}}>
                    Already have an account? Sign In
                </Link>
            </Box>
            <PrivacyPolicyDialog open={isPrivacyDialogOpen} onClose={() => setIsPrivacyDialogOpen(false)} />
        </Container>
    )
}

export default SignUp