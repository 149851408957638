import Box from "@mui/material/Box";
import {CircularProgress} from "@mui/material";
import React from "react";

export const LoadingIndicator = ({children, isLoading}) => {
    return (
        <>
            {
                isLoading ?
                    <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%'}}>
                        <CircularProgress color='secondary' size={60}/>
                    </Box> :
                    children
            }
        </>
    )
}