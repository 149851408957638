import React from "react";
import {CareerType, RoutePath} from "../../utils/enums.js";
import Button from "@mui/material/Button";
import CardActions from "@mui/material/CardActions";
import {useHistory} from "react-router-dom";
import {useAuth} from "../../contexts/AuthContext.js";

export const CareerCardStatusButtons = ({career, redirectToApplyForm = false}) => {
    const history = useHistory();
    const {authenticatedUser, isAuthenticated} = useAuth();

    return <CardActions sx={{justifyContent: 'center'}}>
        {
            career.status === CareerType.APPROVED.name ?
                (
                    !isAuthenticated() ||
                    !authenticatedUser.appliedCareers.includes(career.id) ?
                        <Button variant="contained" sx={{mb: 2}}
                                onClick={(event) => {
                                    event.stopPropagation();
                                    history.push(redirectToApplyForm ? RoutePath.APPLICATION_CREATE(career.id) : RoutePath.CAREER_VIEW(career.id))
                                }}>Apply</Button> :
                        <Button variant="contained" color="primary" sx={{mb: 2}} disabled>Applied</Button>
                ) :
                <Button variant="contained" color="primary" sx={{mb: 2}} disabled>No longer accepting
                    applications</Button>
        }
    </CardActions>
}