import * as React from 'react';
import {AiFillStar} from "react-icons/ai";
import {MdPerson, MdPerson2, MdPerson4} from "react-icons/md";

export default function EmployerTestimonials() {
    return (
        <section className="testimonial-wrapper section-padding fix">
            <div className="container">
                <div className="col-lg-8 col-xl-6 offset-xl-3 col-12 offset-lg-2 text-center">
                    <div className="block-contents">
                        <div className="section-title wow fadeInDown" data-wow-duration="1.2s">
                            <h2>Reviews</h2>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-12 col-lg-4">
                        <div className="single-testimoinal-box">
                            <div className="custom-icon" style={{textAlign: 'center'}}>
                                <div className="icon small">
                                    <MdPerson2/>
                                </div>
                            </div>
                            <div className="feedback">
                                “MC made our hiring process a breeze! I appreciated their efficient and supportive
                                approach. They helped us hire the right people quickly, saving us valuable time and
                                allowing us to focus on our projects.”
                            </div>
                            <div className="client-info">
                                <div className="client-name">
                                    <h6>James R.</h6>
                                    <span>Construction Manager</span>
                                </div>
                            </div>
                            <div className="rating">
                                <AiFillStar className="icon-star"/>
                                <AiFillStar className="icon-star"/>
                                <AiFillStar className="icon-star"/>
                                <AiFillStar className="icon-star"/>
                                <AiFillStar className="icon-star"/>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 col-lg-4">
                        <div className="single-testimoinal-box">
                            <div className="custom-icon" style={{textAlign: 'center'}}>
                                <div className="icon small">
                                <MdPerson/>
                                </div>
                            </div>
                            <div className="feedback">
                                “Working with MC was a refreshing experience! They provided seamless and friendly service that made finding qualified candidates much easier. The team genuinely cares about matching the right people to the right roles, and their support has been invaluable to our hiring process.”
                            </div>
                            <div className="client-info">
                                <div className="client-name">
                                    <h6>Linda J.</h6>
                                    <span>Talent Acquisition Manager @ Hospital</span>
                                </div>
                            </div>
                            <div className="rating">
                                <AiFillStar className="icon-star"/>
                                <AiFillStar className="icon-star"/>
                                <AiFillStar className="icon-star"/>
                                <AiFillStar className="icon-star"/>
                                <AiFillStar className="icon-star"/>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 col-lg-4">
                        <div className="single-testimoinal-box">
                            <div className="custom-icon" style={{textAlign: 'center'}}>
                                <div className="icon small">
                                    <MdPerson4/>
                                </div>
                            </div>
                            <div className="feedback">
                                “Their team truly listened to our needs and helped us find the right people fast. I can’t thank MC enough for their support!”
                            </div>
                            <div className="client-info">
                                <div className="client-name">
                                    <h6>Scott T.</h6>
                                    <span>Residential & Commercial Concrete Services</span>
                                </div>
                            </div>
                            <div className="rating">
                                <AiFillStar className="icon-star"/>
                                <AiFillStar className="icon-star"/>
                                <AiFillStar className="icon-star"/>
                                <AiFillStar className="icon-star"/>
                                <AiFillStar className="icon-star"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
