import React, {useState} from 'react'
import {useHistory} from 'react-router-dom'
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import {RoutePath} from "../../utils/enums";
import {
    companyWelcomeEmailTemplate,
    isEmpty,
    logSentryException,
    newEmployerAdminNotificationEmailTemplate,
    sendGenericEmail
} from "../../utils/utils";
import isURL from "validator/es/lib/isURL";
import isEmail from "validator/es/lib/isEmail";
import {useSnackbar} from "../../contexts/SnackbarContext.js";
import Avatar from "@mui/material/Avatar";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined.js";
import {Employer} from "../../entities/Employer.js";
import {EmployerManager} from "../../managers/EmployerManager.js";
import {analytics} from "../../firebase-config.js";
import {logEvent} from "firebase/analytics";

const EmployerSignUp = () => {
    const history = useHistory();
    const {openSnackbar} = useSnackbar();
    const [errors, setErrors] = useState({})
    const [isFormSubmitting, setIsFormSubmitting] = useState(false);
    const [employer, setEmployer] = useState(new Employer({}));

    const handleSubmit = async (event) => {
        event.preventDefault();

        let formValidationErrors = {};

        try {
            setErrors({})
            setIsFormSubmitting(true);

            formValidationErrors = validateForm();

            if (Object.values(formValidationErrors).indexOf(true) >= 0) {
                throw Error;
            }

            const savedEmployer = await EmployerManager.create(employer);

            history.push(RoutePath.EMPLOYER_CONFIRMATION);

            await sendEmail(savedEmployer);
            logEvent(analytics, 'employer_sign_up_submit', {
                method: 'Employer Sign-Up Form Submission'
            });
        } catch (e) {
            const errorMessage = e.message ? e.message : 'Please check the form for errors.';
            setErrors(formValidationErrors);
            openSnackbar(errorMessage, 'error');
            logSentryException(e);
        } finally {
            setIsFormSubmitting(false);
        }
    };

    const sendEmail = async () => {
        try {
            await sendGenericEmail({
                to: employer.pocEmail,
                subject: `Meaningful Careers: Welcome!`,
                content: companyWelcomeEmailTemplate(employer.pocFullName)
            });

            await sendGenericEmail({
                to: process.env.REACT_APP_MC_ADMIN_EMAIL,
                subject: `New employer sign up: ${employer.name}`,
                content: newEmployerAdminNotificationEmailTemplate(employer)
            });
        } catch (e) {
            openSnackbar('Notification email failed to send.', 'error');
            logSentryException(e);
        }
    }

    const validateForm = () => {
        const errors = {};

        errors.name = isEmpty(employer.name);
        errors.websiteUrl = !isURL(employer.websiteUrl);
        errors.rolesHiringFor = isEmpty(employer.rolesHiringFor);
        errors.pocFullName = isEmpty(employer.pocFullName);
        errors.pocEmail = !isEmail(employer.pocEmail);

        return errors;
    }

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline/>
            <Box component="form" onSubmit={handleSubmit} noValidate
                 sx={{
                     mt: 8,
                     display: 'flex',
                     flexDirection: 'column',
                     alignItems: 'center',
                 }}
            >
                <Avatar sx={{m: 1, bgcolor: 'primary.main'}}>
                    <LockOutlinedIcon/>
                </Avatar>
                <Typography component="h1" variant="h4" sx={{my: 1}}>
                    Employer Sign Up
                </Typography>

                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="name"
                    label="Company Name"
                    name="name"
                    error={errors.name}
                    onChange={(e) => setEmployer({...employer, name: e.target.value})}
                    value={employer.name ? employer.name : ''}
                />
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="rolesHiringFor"
                    label="What roles are you hiring for?"
                    name="rolesHiringFor"
                    error={errors.rolesHiringFor}
                    onChange={(e) => setEmployer({...employer, rolesHiringFor: e.target.value})}
                    value={employer.rolesHiringFor ? employer.rolesHiringFor : ''}
                />
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="websiteUrl"
                    label="Website URL"
                    name="websiteUrl"
                    autoComplete="off"
                    type='url'
                    error={errors.websiteUrl}
                    onChange={(e) => setEmployer({...employer, websiteUrl: e.target.value})}
                    value={employer.websiteUrl ? employer.websiteUrl : ''}
                />
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="pocFullName"
                    label="Point of Contact Full Name"
                    name="pocFullName"
                    autoComplete="off"
                    error={errors.pocFullName}
                    onChange={(e) => setEmployer({...employer, pocFullName: e.target.value})}
                    value={employer.pocFullName ? employer.pocFullName : ''}
                />
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    type='email'
                    id="pocEmail"
                    label="Point of Contact Email"
                    name="pocEmail"
                    autoComplete="off"
                    error={errors.pocEmail}
                    onChange={(e) => setEmployer({...employer, pocEmail: e.target.value})}
                    value={employer.pocEmail ? employer.pocEmail : ''}
                />
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{my: 2, textTransform: 'capitalize', fontSize: '1rem'}}
                    disabled={isFormSubmitting}
                >
                    Sign Up
                </Button>
            </Box>
        </Container>
    )
}

export default EmployerSignUp