import * as React from 'react';

export default function ContentSection() {
    return (
        <section className="promo-content-block fix section-padding">
            <div className="container">
                <div className="row">
                    <div className="col-xl-6 col-12">
                        <img src="/images/candidates-banner.png" alt="banner 1"/>
                    </div>
                    <div className="col-xl-6 col-12 ps-xl-5 mt-5 mt-xl-0"
                         style={{display: 'flex', alignItems: 'center'}}>
                        <div className="block-contents ms-xl-5">
                            <div className="section-title mb-4">
                                <h2 className="wow fadeInUp">Mission</h2>
                                <p className="wow fadeInUp pt-15" data-wow-delay=".3s">
                                    At Meaningful Careers (MC), our mission is to empower individuals and businesses in
                                    healthcare and construction to build a brighter future. Whether you're advancing
                                    your career or searching for top talent, our AI-powered platform and personalized
                                    services are here to support you every step of the way. We’re committed to creating
                                    meaningful connections that drive success for both career seekers and employers.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
