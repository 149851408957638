import React, {useCallback, useEffect, useRef, useState} from 'react'
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import {SearchCategory} from "../../utils/enums.js";
import {CircularProgress} from "@mui/material";
import {useSnackbar} from "../../contexts/SnackbarContext.js";
import {logSentryException, searchRecords} from "../../utils/utils.js";
import {SearchBar} from "../universal/SearchBar.js";
import {UserManager} from "../../managers/UserManager.js";
import {User} from "../../entities/User.js";
import {ProfileCard} from "./ProfileCard.js";

export const ProfileList = () => {
    const {openSnackbar} = useSnackbar();
    const [query, setQuery] = useState('');
    const [profiles, setProfiles] = useState([])
    const [isLoading, setIsLoading] = useState(true);
    const [isSearchMode, setIsSearchMode] = useState(false);
    const [totalRecordCount, setTotalRecordCount] = useState(0);
    const [lastDoc, setLastDoc] = useState(null);
    const [nextPage, setNextPage] = useState(0);

    const observer = useRef();

    const lastElementRef = useCallback(
        (node) => {
            const hasMoreRecords = profiles.length < totalRecordCount;

            if (isLoading) {
                return;
            }

            if (observer.current) {
                observer.current.disconnect();
            }

            observer.current = new IntersectionObserver(entries => {
                if (entries[0].isIntersecting && hasMoreRecords && !isLoading) {
                    if (isSearchMode) {
                        searchProfiles(nextPage).then().catch(e => {
                            openSnackbar(e.message, 'error')
                            logSentryException(e);
                        });
                    } else {
                        getProfiles().then().catch(e => {
                            openSnackbar(e.message, 'error')
                            logSentryException(e);
                        });
                    }
                }
            });

            if (node) {
                observer.current.observe(node);
            }
        },
        [isLoading, profiles.length < totalRecordCount]
    );

    useEffect(() => {
        setIsLoading(true);
        setIsSearchMode(false);

        setUp()
            .then()
            .catch((e) => {
                openSnackbar('Something went wrong while loading profile list.', 'error')
                logSentryException(e);
            })
            .finally(() => setIsLoading(false));
    }, []);

    const setUp = async () => {
        setProfiles([]);

        const totalCount = await UserManager.getTotalCount();
        setTotalRecordCount(totalCount);

        await getProfiles(true);
    };

    const searchProfiles = async (page = 0, isFresh = false) => {
        try {
            setIsLoading(true);
            setIsSearchMode(true);

            if (isFresh) {
                setProfiles([]);
            }

            const existingProfiles = page > 0 ? [...profiles] : [];
            const result = await searchRecords({
                query: query,
                pageNumber: page,
                category: SearchCategory.PROFILES,
            });
            const newRecords = result.data.data ? result.data.data.map((u) => (new User(u))) : [];

            setProfiles([...(isFresh ? [] : existingProfiles), ...newRecords]);
            setTotalRecordCount(result.data.totalSize);
            setNextPage(page + 1);
        } catch (e) {
            openSnackbar(e.message, 'error');
            logSentryException(e);
        } finally {
            setIsLoading(false);
        }
    }

    const getProfiles = async (isFresh = false) => {
        setIsLoading(true);

        const newRecords = await UserManager.getAll(isFresh ? null : lastDoc);

        setProfiles(applications => [...(isFresh ? [] : applications), ...newRecords.data]);
        setLastDoc(newRecords.lastDoc);

        setIsLoading(false);
    }

    return (
        <main>
            <CssBaseline/>
            <Container maxWidth="md" sx={{mt: 3}}>
                <Typography variant="h3" align="center" color="text.primary">
                    Profiles
                </Typography>
                <SearchBar query={query}
                           setQuery={setQuery}
                           placeholder={'Search profiles ...'}
                           showAILabel={true}
                           onSearch={async () => await searchProfiles(0, true)}/>
                <Typography component="p" align="center" sx={{mt: 3, textAlign: 'center'}}>
                    Total users: {totalRecordCount}
                </Typography>
            </Container>
            <Container maxWidth="lg" sx={{mt: 5}}>
                {
                    <Grid container spacing={4} maxWidth="lg">
                        {
                            profiles.map((profile, idx) =>
                                <Grid item key={profile.id} xs={12} sm={6} md={4}>
                                    <ProfileCard ref={profiles.length === idx + 1 ? lastElementRef : null}
                                                 profile={profile}/>
                                </Grid>
                            )
                        }
                        {
                            isLoading &&
                            <Box sx={{mx: 'auto', mt: 10}}>
                                <CircularProgress color='secondary' size={60}/>
                            </Box>
                        }
                    </Grid>
                }
            </Container>
        </main>
    );
}
