import React, {useState} from "react"
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import {useHistory} from 'react-router-dom';
import {useAuth} from "../../contexts/AuthContext";
import {RoutePath} from "../../utils/enums";
import {useSnackbar} from "../../contexts/SnackbarContext.js";
import {logSentryException} from "../../utils/utils.js";

const ResetPassword = () => {
    const [email, setEmail] = useState();
    const {resetPassword} = useAuth();
    const [loading, setLoading] = useState(false);
    const history = useHistory();
    const {openSnackbar} = useSnackbar();

    const handleSubmit = async (event) => {
        event.preventDefault();

        try {
            setLoading(true)
            await resetPassword(email)
            history.push(RoutePath.SIGN_IN)
        } catch (e) {
            openSnackbar('Failed to sign in. ' + e.toString().replace('FirebaseError: Firebase:', ''))
            logSentryException(e);
        } finally {
            setLoading(false)
        }
    };

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline/>
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Avatar sx={{m: 1, background: 'secondary.main'}}>
                    <LockOutlinedIcon/>
                </Avatar>
                <Typography component="h1" variant="h5">
                    Forgot/Change Password
                </Typography>
                <Box component="form" onSubmit={handleSubmit} noValidate sx={{mt: 1}}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                        autoFocus
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{mt: 3, mb: 2}}
                        disabled={loading}
                    >
                        Send Reset Email
                    </Button>
                </Box>
            </Box>
        </Container>
    )
}

export default ResetPassword