import React from "react"
import {Redirect, Route} from 'react-router-dom'
import {useAuth} from "../../contexts/AuthContext"
import {RoutePath, RouteRestriction} from "../../utils/enums";

export const ProtectedRoute = ({
                                   children,
                                   restriction = RouteRestriction.ANYONE_AUTHENTICATED,
                                   ...rest
                               }) => {
    const {isAuthenticated, isAdmin} = useAuth()

    const getContent = () => {
        if (
            (restriction === RouteRestriction.ADMIN_ONLY && isAdmin()) ||
            (restriction === RouteRestriction.USER_ONLY && !isAdmin() && isAuthenticated()) ||
            (restriction === RouteRestriction.ANYONE_AUTHENTICATED && isAuthenticated()) ||
            (restriction === RouteRestriction.ANYONE_UNAUTHENTICATED && !isAuthenticated())
        ) {
            return children;
        }

        return isAuthenticated() || (!isAuthenticated() && restriction === RouteRestriction.ADMIN_ONLY) ?
            <Redirect to={RoutePath.CAREER_VIEW_ALL}/> :
            <Redirect to={RoutePath.SIGN_IN}/>;
    }

    return (
        <Route exact {...rest}>
            {getContent()}
        </Route>
    )
}