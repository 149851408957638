import * as React from 'react';
import {RoutePath} from "../../../utils/enums.js";
import {useHistory} from "react-router-dom";

export default function CandidateHero() {
    const history = useHistory();

    return (
        <section className="hero-welcome-wrapper hero-1">
            <div className="single-slide text-white">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-7 col-12 col-lg-10 offset-lg-1 offset-xl-0">
                            <div className="hero-contents">
                                <h1 style={{marginTop: '20px'}}>Your Career, Redefined.</h1>
                                <p>
                                    Tired of sending countless job applications without any
                                    replies? At MC, we’re here to help you find meaningful opportunities in construction
                                    and
                                    healthcare that match your goals. With us, your next career move will be an exciting
                                    journey, not a hassle.
                                </p>
                                <button className="btn" style={{
                                    backgroundColor: 'white',
                                    color: '#5C0E7B',
                                    fontWeight: '500',
                                    width: '190px',
                                    height: '60px',
                                    fontSize: '1.2rem',
                                    marginTop: '20px'
                                }} onClick={() => history.push(RoutePath.SIGN_UP)}>Get Started
                                </button>
                            </div>
                        </div>
                        <div className="col-xl-5 col-12 text-xl-end col-lg-10 offset-lg-1 offset-xl-0">
                            <div className="hero-mobile mt-5 mt-xl-0">
                                <img src="/images/background-candidates.png" alt="employers background"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
