import validator from "validator";
import {RoutePath} from "./enums.js";
import {httpsCallable} from "firebase/functions";
import {functions} from '../firebase-config.js';
import * as Sentry from "@sentry/react";

export const isEmpty = (value) => validator.isEmpty(value, {ignore_whitespace: true});

export const getCareerFormURL = (companyId) => `${window.location.origin}${RoutePath.CAREER_ADD}?companyId=${companyId}`;

export const formatSalary = (salary, isHourly = false) => {
    if (!Array.isArray(salary) || salary.length !== 2) {
        return salary;
    }

    if (salary[0] === salary[1]) {
        return !isHourly ? `$${salary[0]}k/year` : `$${salary[0]}/hr`;
    }

    return !isHourly ? `$${salary[0]}-${salary[1]}k/year` : `$${salary[0]}-${salary[1]}/hr`;
}

export const formatLocation = (locations, isShortVersion = false) => {
    if (isShortVersion && locations.length > 1) {
        return `${[...locations].slice(0, 1).join('; ')}; +${locations.length - 1} more`
    }

    return locations.join('; ');
}

export const firebaseTimeToDateString = (seconds, nanoseconds) => {
    const fbTime = new Date(
        seconds * 1000 + nanoseconds / 1000000,
    );
    return fbTime.toDateString();
}

export const truncateString = (str, maxLength = 25) => {
    if (str.length > maxLength) {
        return str.substring(0, maxLength - 3) + '...';
    }

    return str;
}

export const formatPhoneNumber = (phoneNumber) => {
    const cleaned = ('' + phoneNumber).replace(/\D/g, '');
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

    if (match) {
        return `(${match[1]}) ${match[2]}-${match[3]}`;
    }

    return '';
}

export const getCurrDate = () => {
    const currentDate = new Date();

    const options = {
        weekday: 'short',
        year: 'numeric',
        month: 'short',
        day: 'numeric'
    };

    return currentDate.toLocaleDateString('en-US', options);
}

export const logSentryException = (exception, data = null, tag = null) => {
    Sentry.captureException({
        exception,
        data,
        tag
    })
}

export const searchRecords = httpsCallable(functions, 'searchRecords');
export const sendGenericEmail = httpsCallable(functions, 'sendGenericEmail');
export const generateCareer = httpsCallable(functions, 'generateCareer');
export const getPublicCompanyData = httpsCallable(functions, 'getPublicCompanyData');

// candidate email templates
export const welcomeEmailTemplate = (firstName) => `Hey ${firstName},<br/><br/>
Welcome to MC! We're thrilled to have you join our platform and embark on your career journey with us.<br/><br/>
Our team is committed to providing you with a meaningful and seamless experience. New career opportunities are added every day, ensuring you have a wide range of options to explore. If you have any questions or need assistance, please don't hesitate to reach out. Our dedicated MC Career Advisors are here to support you every step of the way.<br/><br/>
Best regards,<br/>
MC Talent Team`

export const applicationSubmittedCandidateEmailTemplate = (candidate, career) => `Hey ${candidate.firstName} ${candidate.lastName},<br/><br/>
Thank you for applying for the ${career.title} position at MC! We're excited to support you in your career search journey.<br/><br/>
Our team is dedicated to ensuring you have a meaningful experience. You can expect an email from one of our MC Hiring Managers shortly. They'll guide you through the next steps and answer any questions you may have.<br/><br/>
Best regards,<br/>
MC Talent Team`

// company email templates
export const companyWelcomeEmailTemplate = (pocName) => `Hey ${pocName},<br/><br/>
Thank you for signing up with MC! We're excited to support your recruitment needs and help you find the best talent for your team.<br/><br/>
Our team is dedicated to ensuring you have a smooth and successful hiring experience. One of our MC Account Managers will be in touch shortly to guide you through the next steps and answer any questions you may have.<br/><br/>
We're looking forward to speaking with you soon!<br/><br/>
Best regards,<br/>
MC Talent Team`

export const companyApplicationNotificationEmailTemplate = (pocName, role, applications) => `Hey ${pocName},<br/><br/>
These are the newly selected candidates for the '${role}' career:<br/>
${applications.map(a => `${a.user.firstName} ${a.user.lastName} - <a href='${a.resumeLink}'>Resume Link</a>`).join('<br/>')}<br/><br/>
Best regards,<br/>
MC Talent Team`

export const confirmedCompanyCareerFormLinkEmailTemplate = (pocName, formURL) => `Hey ${pocName},<br/><br/>
Welcome to Meaningful Careers! To submit a new career, please fill out <a href='${formURL}'>this form</a>.<br/><br/>
Best regards,<br/>
MC Talent Team`

// admin email templates
export const applicationSubmittedAdminNotificationEmailTemplate = (candidate, career) => `New application! ${candidate.firstName} ${candidate.lastName} - ${career.title}`

export const newEmployerAdminNotificationEmailTemplate = (employer) => `<b>Employer sign up details</b><br/><br/>` +
    `<b>Name:</b> ${employer.name}<br/>` +
    `<b>Website:</b> ${employer.websiteUrl}<br/>` +
    `<b>Roles they are hiring for:</b> ${employer.rolesHiringFor}<br/>` +
    `<b>PoC full name:</b> ${employer.pocFullName}<br/>` +
    `<b>PoC email:</b> ${employer.pocEmail}`

export const newCandidateAdminNotificationEmailTemplate = (candidate) => `New candidate! ${candidate.firstName} ${candidate.lastName} signed up.`