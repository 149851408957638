import * as React from 'react';
import {useCallback, useEffect, useRef, useState} from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import {useHistory} from 'react-router-dom';
import {RoutePath, SearchCategory} from "../../utils/enums";
import {CompanyCard} from "./CompanyCard";
import {CompanyManager} from "../../managers/CompanyManager";
import {FloatingActionButton} from "../universal/FloatingActionButton.js";
import {Add} from "@mui/icons-material";
import {SearchBar} from "../universal/SearchBar.js";
import {CircularProgress} from "@mui/material";
import {logSentryException, searchRecords} from "../../utils/utils.js";
import {Company} from "../../entities/Company.js";
import {useSnackbar} from "../../contexts/SnackbarContext.js";

export default function CompanyList() {
    const history = useHistory()
    const [companies, setCompanies] = useState([])
    const [query, setQuery] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [isSearchMode, setIsSearchMode] = useState(false);
    const [totalRecordCount, setTotalRecordCount] = useState(0);
    const [lastDoc, setLastDoc] = useState(null);
    const [nextPage, setNextPage] = useState(0);
    const {openSnackbar} = useSnackbar();

    const observer = useRef();

    const lastElementRef = useCallback(
        (node) => {
            const hasMoreRecords = companies.length < totalRecordCount;

            if (isLoading) {
                return;
            }

            if (observer.current) {
                observer.current.disconnect();
            }

            observer.current = new IntersectionObserver(entries => {
                if (entries[0].isIntersecting && hasMoreRecords && !isLoading) {
                    if (isSearchMode) {
                        searchCompanies(nextPage).then().catch(e => {
                            openSnackbar(e.message, 'error')
                            logSentryException(e);
                        });
                    } else {
                        getCompanies().then().catch(e => {
                            openSnackbar(e.message, 'error')
                            logSentryException(e);
                        });
                    }
                }
            });

            if (node) {
                observer.current.observe(node);
            }
        },
        [isLoading, companies.length < totalRecordCount]
    );

    useEffect(() => {
        setUp()
            .then()
            .catch((e) => {
                openSnackbar('Something went wrong while loading company list.', 'error')
                logSentryException(e);
            })
            .finally(() => setIsLoading(false));
    }, []);

    const setUp = async () => {
        const totalCount = await CompanyManager.getTotalCount();
        setTotalRecordCount(totalCount);
        setIsSearchMode(false);

        await getCompanies();
    }

    const searchCompanies = async (page = 0, isFresh = false) => {
        try {
            const existingCompanies = page > 0 ? [...companies] : [];

            setIsLoading(true);
            setIsSearchMode(true);

            if (isFresh) {
                setCompanies([]);
            }

            const result = await searchRecords({query: query, pageNumber: page, category: SearchCategory.COMPANIES})
            const newRecords = result.data.data ? result.data.data.map((c) => (new Company(c))) : [];

            setCompanies([...(isFresh ? [] : existingCompanies), ...newRecords]);
            setTotalRecordCount(result.data.totalSize);
            setNextPage(page + 1);
        } catch (e) {
            openSnackbar(e.message, 'error');
            logSentryException(e);
        } finally {
            setIsLoading(false);
        }
    }

    const getCompanies = async () => {
        setIsLoading(true);

        const newRecords = await CompanyManager.getAll(true, lastDoc);
        setCompanies([...companies, ...newRecords.data]);
        setLastDoc(newRecords.lastDoc);

        setIsLoading(false);
    }

    return (
        <main>
            <CssBaseline/>
            <Container maxWidth="md" sx={{mt: 3}}>
                <Box>
                    <Typography
                        variant="h3"
                        align="center"
                        color="text.primary"
                        gutterBottom
                    >
                        Companies
                    </Typography>

                    <FloatingActionButton title='Add Company'
                                          onClick={() => history.push(RoutePath.COMPANY_ADD)}
                                          icon={<Add/>}/>
                </Box>
                <SearchBar query={query}
                           setQuery={setQuery}
                           placeholder={'Search records ...'}
                           showAILabel={true}
                           onSearch={async () => {
                               await searchCompanies(0, true);
                           }}/>
            </Container>
            <Container maxWidth="lg" sx={{mt: 5}}>
                <Grid container spacing={4} maxWidth="lg">
                    {
                        companies.map((company, idx) => (
                            <Grid item key={`company-grid-item-${idx}`} xs={12} sm={6} md={4}>
                                <CompanyCard ref={companies.length === idx + 1 ? lastElementRef : null}
                                             key={`company-card-${idx}`} company={company}/>
                            </Grid>
                        ))
                    }
                    {
                        isLoading &&
                        <Box sx={{mx: 'auto', mt: 10}}>
                            <CircularProgress color='secondary' size={60}/>
                        </Box>
                    }
                </Grid>
            </Container>
        </main>
    );
}