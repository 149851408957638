import {
    addDoc,
    collection,
    doc,
    documentId,
    getCountFromServer,
    getDoc,
    getDocs,
    limit,
    onSnapshot,
    orderBy,
    query,
    serverTimestamp,
    setDoc,
    startAfter,
    where
} from "firebase/firestore";
import {db} from "../firebase-config.js";
import {Career} from "../entities/Career.js";
import {CompanyManager} from "./CompanyManager.js";
import {CareerType} from "../utils/enums.js";
import {Application} from "../entities/Application.js";
import {RESULTS_PER_PAGE} from "../utils/constants.js";

export class CareerManager {
    static async getAllByIdsObservable(setter, careerIds) {
        return new Promise((resolve, reject) => {
            if (careerIds.length <= 0) {
                resolve(() => null);
                setter([]);
                return;
            }

            let q = query(
                collection(db, Career.collectionName),
                where(documentId(), 'in', careerIds)
            );

            const unsubscribe = onSnapshot(q, (snapshot) => {
                    const data = snapshot.docs.map(
                        (doc) => (new Career({...doc.data(), id: doc.id}))
                    );

                    setter(data);
                    resolve(unsubscribe);
                },
                (error) => {
                    reject(error);
                }
            );
        });
    }

    static async getAll(status = CareerType.APPROVED.name, lastDoc, sort = false) {
        const constraints = [
            where('status', '==', status),
            limit(RESULTS_PER_PAGE)
        ];

        if (sort) {
            constraints.push(orderBy('title'));
        }

        if (lastDoc) {
            constraints.push(startAfter(lastDoc));
        }

        const q = query(collection(db, Career.collectionName), ...constraints);
        const snapshot = await getDocs(q);
        const docs = snapshot.docs;

        return {
            data: docs.map((document) => (new Career({...document.data(), id: document.id}))),
            lastDoc: docs.length > 0 ? docs[docs.length - 1] : null,
        };
    }

    static async getById(id, includeCompany = false) {
        const document = await getDoc(doc(db, Career.collectionName, id));
        const career = new Career({...document.data(), id});

        if (includeCompany) {
            career.company = await CompanyManager.getById(career.companyId);
        }

        return career;
    }

    static getByIdObservable(id, setter) {
        return new Promise((resolve, reject) => {
            let isPromiseResolved = false;
            const q = query(
                collection(db, Career.collectionName),
                where(documentId(), '==', id)
            );

            const unsubscribe = onSnapshot(q, (snapshot) => {
                    const data = snapshot.docs.map(
                        (doc) => (new Career({...doc.data(), id: doc.id}))
                    );

                    if (data.length > 0) {
                        setter(data[0]);

                        if (!isPromiseResolved) {
                            resolve({career: data[0], unsubscribe});
                            isPromiseResolved = true;
                        }
                    } else if (!isPromiseResolved) {
                        resolve({career: null, unsubscribe});
                        isPromiseResolved = true;
                    }
                },
                (error) => {
                    reject(error);
                }
            )
        });
    }

    static async getTotalCount(status) {
        const constraints = [
            where('status', '==', status),
        ];

        const totalQuery = query(collection(db, Career.collectionName), ...constraints);

        const totalSnapshot = await getCountFromServer(totalQuery);

        return totalSnapshot.data().count;
    }

    static create(career) {
        return addDoc(
            collection(db, Career.collectionName),
            {...career, status: career.status ? career.status : CareerType.PENDING.name, createdAt: serverTimestamp()}
        );
    }

    static async update(career) {
        if (career.status === CareerType.ARCHIVED.name) {
            const applicationsSnapshot = query(
                collection(db, Application.collectionName),
                where('careerId', '==', career.id),
                where('isFinalized', '==', false),
                limit(1),
            );
            const activeSnapshot = await getDocs(applicationsSnapshot);

            if (!activeSnapshot.empty) {
                throw Error('You cannot archive a job because there are some non-finalized applications');
            }
        }

        return setDoc(doc(db, Career.collectionName, career.id), {...career}, {merge: true});
    }
}