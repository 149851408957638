import {
    addDoc,
    collection,
    doc,
    getCountFromServer,
    getDoc,
    getDocs,
    limit,
    orderBy,
    query,
    serverTimestamp,
    setDoc,
    startAfter,
} from "firebase/firestore";
import {db} from "../firebase-config.js";
import {Company} from "../entities/Company.js";
import {RESULTS_PER_PAGE} from "../utils/constants.js";

export class CompanyManager {
    static async getAll(isPaginated = false, lastDoc) {
        const constraints = [orderBy('name')];

        if (isPaginated) {
            constraints.push(limit(RESULTS_PER_PAGE));
        }

        if (lastDoc) {
            constraints.push(startAfter(lastDoc));
        }

        const q = query(collection(db, Company.collectionName), ...constraints);
        const snapshot = await getDocs(q);
        const docs = snapshot.docs;

        return {
            data: docs.map((document) => (new Company({...document.data(), id: document.id}))),
            lastDoc: docs.length > 0 ? docs[docs.length - 1] : null,
        };
    }

    static async getById(id) {
        if (!id) {
            return null;
        }

        const document = await getDoc(doc(db, Company.collectionName, id))

        if (!document.exists()) {
            return null;
        }

        return new Company({...document.data(), id});
    }

    static async getTotalCount() {
        const totalQuery = query(collection(db, Company.collectionName));

        const totalSnapshot = await getCountFromServer(totalQuery);

        return totalSnapshot.data().count;
    }

    static create(company) {
        return addDoc(collection(db, Company.collectionName), {...company, createdAt: serverTimestamp()})
    }

    static update(company) {
        return setDoc(doc(db, Company.collectionName, company.id), {...company}, {merge: true});
    }
}