import * as React from 'react';
import {AiOutlineBarChart} from "react-icons/ai";
import {PiExcludeSquareDuotone} from "react-icons/pi";
import {TbGhostOff} from "react-icons/tb";
import {GiBrain} from "react-icons/gi";

export default function CandidateFeatures() {
    return (
        <section className="our-best-features-wrapper section-padding">
            <div className="container">
                <div className="text-center">
                    <div className="block-contents">
                        <div className="section-title">
                            <p style={{marginTop: '30px', fontSize: '110%'}}>MC isn't just another AI career platform. With over 30 years
                                of expertise in construction and healthcare recruitment, we're here to help you discover
                                exciting opportunities in these dynamic industries.</p>
                            <p style={{fontSize: '110%'}}>Whether you're starting your career
                                or seeking a leadership role, we make your job search simple, meaningful, and—yes, even
                                fun! Plus, with personalized support from our team, you’ll have all the resources you
                                need to land a meaningful career!</p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6 col-xl-3 col-12" style={{display: 'flex', flexDirection: 'column'}}>
                        <div className="single-core-feature" style={{flexGrow: 1}}>
                            <div className="custom-icon">
                                <div className="icon purple">
                                    <TbGhostOff/>
                                </div>
                            </div>
                            <div className="content">
                                <h3>Real Career Opportunities, No Ghosting</h3>
                                <p>We partner with leading construction and healthcare companies that are actively
                                    hiring. Say goodbye to chasing ghost jobs—only genuine opportunities here.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-xl-3 col-12" style={{display: 'flex', flexDirection: 'column'}}>
                        <div className="single-core-feature" style={{flexGrow: 1}}>
                            <div className="custom-icon">
                                <div className="icon purple">
                                    <AiOutlineBarChart/>
                                </div>
                            </div>
                            <div className="content">
                                <h3>Stay in Control with Our ATS</h3>
                                <p>Our built-in Applicant Tracking System lets you easily apply, track your progress,
                                    and manage all your applications in one spot. No more getting lost or wondering
                                    what’s next.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-xl-3 col-12" style={{display: 'flex', flexDirection: 'column'}}>
                        <div className="single-core-feature" style={{flexGrow: 1}}>
                            <div className="custom-icon">
                                <div className="icon purple">
                                    <PiExcludeSquareDuotone/>
                                </div>
                            </div>
                            <div className="content">
                                <h3>Resources to Enhance Your Career</h3>
                                <p>From career coaching to upskilling programs, we offer resources to help you build the
                                    skills you need to succeed in your next role.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-xl-3 col-12" style={{display: 'flex', flexDirection: 'column'}}>
                        <div className="single-core-feature" style={{flexGrow: 1}}>
                            <div className="custom-icon">
                                <div className="icon purple">
                                    <GiBrain/>
                                </div>
                            </div>
                            <div className="content">
                                <h3>Support You Can Rely On</h3>
                                <p>Our friendly and experienced team is here to help you at every step, from your
                                    application to the final interview. With helpful tips and personalized advice,
                                    you’ll be ready to shine.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
