import React from "react";
import {Avatar, Box} from '@mui/material';
import {useAuth} from "../../contexts/AuthContext";
import {Edit} from "@mui/icons-material";

export const ProfileAvatar = ({setIsImageUploadDialogOpen}) => {
    const {authenticatedUser} = useAuth();

    return (
        <Box
            width={100}
            height={100}
            position="relative"
            overflow="hidden"
            onClick={() => setIsImageUploadDialogOpen(true)}
            sx={{
                '&:hover img': {
                    opacity: 0.6
                },
                '&:hover .cover': {
                    opacity: 1
                }
            }}
        >
            <Avatar
                alt='user-avatar'
                src={authenticatedUser.photoURL ? authenticatedUser.photoURL : ''}
                sx={{width: '100%', height: '100%', transition: 'opacity 0.3s'}}
            />
            <Box
                className="cover"
                position="absolute"
                top={0}
                right={0}
                bottom={0}
                left={0}
                bgcolor="rgba(0, 0, 0, 0.5)"
                display="flex"
                alignItems="center"
                justifyContent="center"
                color="white"
                sx={{borderRadius: '50%', opacity: 0, transition: 'opacity 0.3s'}}
            >
                <Edit/>
            </Box>
        </Box>
    );
}