import * as React from 'react';
import HomeHero from "./HomeHero.js";
import HomeFeatures from "./HomeFeatures.js";

export const HomePage = () => {
    return (
        <span className="styles">
            <HomeHero/>
            <HomeFeatures/>
        </span>
    )
};