export class Employer {
    static collectionName = 'company_waitlist';

    constructor(data) {
        this.id = data.id ? data.id : '';
        this.name = data.name ? data.name : '';
        this.websiteUrl = data.websiteUrl ? data.websiteUrl : '';
        this.rolesHiringFor = data.rolesHiringFor ? data.rolesHiringFor : '';
        this.pocFullName = data.pocFullName ? data.pocFullName : '';
        this.pocEmail = data.pocEmail ? data.pocEmail : '';
        this.createdAt = data.createdAt ? data.createdAt : null;
    }
}