import React, {useEffect, useState} from 'react'
import {useHistory, useParams} from 'react-router-dom'
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Grid from "@mui/material/Grid";
import CssBaseline from "@mui/material/CssBaseline";
import {ApplicationStatusStepper} from "./ApplicationStatusStepper";
import {Divider, TextField} from "@mui/material";
import {Launch} from "@mui/icons-material";
import {ApplicationManager} from "../../managers/ApplicationManager";
import {UserManager} from "../../managers/UserManager";
import {AllowedJobStatusActions} from "../../utils/enums";
import {getCurrDate, logSentryException} from "../../utils/utils";
import Box from "@mui/material/Box";
import {LoadingIndicator} from "../universal/LoadingIndicator.js";
import {CustomDropdown} from "../universal/CustomDropdown.js";
import {ApplicationCardContent} from "./ApplicationCardContent.js";
import {userPalette} from "../../utils/theme.js";
import {useSnackbar} from "../../contexts/SnackbarContext.js";

export const ApplicationDetails = () => {
    const {applicationId, userId} = useParams();
    const {openSnackbar} = useSnackbar();
    const [application, setApplication] = useState({})
    const [applicant, setApplicant] = useState({})
    const [statusHistory, setStatusHistory] = useState([])
    const [possibleNextStatuses, setPossibleNextStatuses] = useState([])
    const [isUpdateStatusLoading, setIsUpdateStatusLoading] = useState(false)
    const [isSaveAdminNotesLoading, setIsSaveAdminNotesLoading] = useState(false)
    const [loadingContent, setLoadingContent] = useState(true);
    const history = useHistory();

    const handleSubmit = async (event) => {
        event.preventDefault();

        try {
            setIsUpdateStatusLoading(true);

            const updatedApplication = {...application, statusHistory};

            // todo: save admin notes as well
            // todo: create a firebase function to restrict access to adminNotes
            await ApplicationManager.update(updatedApplication);
            setApplication(updatedApplication);
            openSnackbar('Application updated successfully.');
        } catch (e) {
            openSnackbar(e.message, 'error');
            logSentryException(e);
        } finally {
            setIsUpdateStatusLoading(false);
        }
    };

    const handleSaveAdminNotes = async (event) => {
        event.preventDefault();

        try {
            setIsSaveAdminNotesLoading(true);
            await ApplicationManager.update(application);
            openSnackbar('Admin notes saved successfully.');
        } catch (e) {
            openSnackbar(e.message, 'error');
            logSentryException(e);
        } finally {
            setIsSaveAdminNotesLoading(false);
        }
    };

    useEffect(() => {
        setUp()
            .then()
            .catch((e) => {
                openSnackbar('Something went wrong: ' + e.message, 'error');
                logSentryException(e);
            })
            .finally(() => setLoadingContent(false));
    }, []);

    useEffect(() => {
        setPossibleNextStatuses(application.statusHistory && AllowedJobStatusActions[application.statusHistory[application.statusHistory.length - 1].status])
    }, [application.statusHistory]);

    const setUp = async () => {
        const application = await ApplicationManager.getById(applicationId);

        setApplication(application);
        setStatusHistory(application.statusHistory)
        setPossibleNextStatuses(application.statusHistory && AllowedJobStatusActions[application.statusHistory[application.statusHistory.length - 1].status])

        const user = await UserManager.getById(userId);

        setApplicant(user);
    }

    return (
        <Container maxWidth="md" sx={{py: 5}}>
            <CssBaseline/>
            <LoadingIndicator isLoading={loadingContent}>
                <Grid container spacing={2}>
                    <Button
                        type="button"
                        fullWidth
                        variant="contained"
                        sx={{mt: 1, mb: 2, mx: 'auto', width: 'fit-content'}}
                        onClick={() => history.goBack()}
                    >
                        Go back to other applications
                    </Button>
                    <ApplicationStatusStepper steps={application.statusHistory ? application.statusHistory : []}/>
                    <Divider sx={{my: 1}}/>
                    <Grid item xs={12} sx={{display: 'flex', justifyContent: 'center'}}>
                        <ApplicationCardContent applicant={applicant} isShortVersion={false} sx={{maxWidth: '300px'}}/>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        <Typography gutterBottom variant="span" sx={{display: 'flex', alignItems: 'center'}}>
                            <span style={{fontWeight: 'bold'}}>Resume: </span>
                            <a href={application.resumeLink ? application.resumeLink : '#'}
                               target="_blank"
                               style={{textDecoration: 'none', color: userPalette.main, marginLeft: 5}}>
                                View here
                                <Launch sx={{color: userPalette.main, ml: .5, fontSize: 'small'}}/>
                            </a>
                        </Typography>
                        <Typography gutterBottom variant="span" sx={{display: 'flex', alignItems: 'center'}}>
                            <span style={{fontWeight: 'bold'}}>Cover Letter: </span>
                            {
                                application.coverLetterLink ?
                                    <a href={application.coverLetterLink}
                                       target="_blank"
                                       style={{textDecoration: 'none', color: userPalette.main, marginLeft: 5}}>
                                        View here
                                        <Launch sx={{color: userPalette.main, ml: .5, fontSize: 'small'}}/>
                                    </a> :
                                    <span style={{marginLeft: 5}}> Not Provided</span>
                            }
                        </Typography>

                        <Typography gutterBottom variant="span" sx={{display: 'flex', alignItems: 'center', mt: 2}}>
                            <span style={{fontWeight: 'bold'}}>LinkedIn: </span>
                            {
                                applicant.linkedInURL ?
                                    <a href={applicant.linkedInURL}
                                       target="_blank"
                                       style={{textDecoration: 'none', color: userPalette.main, marginLeft: 5}}>
                                        View here
                                        <Launch sx={{color: userPalette.main, ml: .5, fontSize: 'small'}}/>
                                    </a> :
                                    <span style={{marginLeft: 5}}> Not Provided</span>
                            }
                        </Typography>

                        <Typography gutterBottom variant="span" sx={{display: 'flex', alignItems: 'center'}}>
                            <span style={{fontWeight: 'bold'}}>Portfolio: </span>
                            {
                                applicant.portfolioURL ?
                                    <a href={applicant.portfolioURL}
                                       target="_blank"
                                       style={{textDecoration: 'none', color: userPalette.main, marginLeft: 5}}>
                                        View here
                                        <Launch sx={{color: userPalette.main, ml: .5, fontSize: 'small'}}/>
                                    </a> :
                                    <span style={{marginLeft: 5}}> Not Provided</span>
                            }
                        </Typography>
                    </Grid>
                    <Grid item
                          container
                          xs={12} sm={12} md={6}
                          sx={{justifyContent: {xs: 'center', md: 'flex-end'}}}>
                        <Box component="form" onSubmit={handleSubmit} noValidate sx={{mt: 1}}>
                            {
                                possibleNextStatuses ?
                                    <Box>
                                        <CustomDropdown label='Next Status'
                                                        value={statusHistory.length > 0 ? statusHistory[statusHistory.length - 1].status : ''}
                                                        onChange={(e) => setStatusHistory([...application.statusHistory, {
                                                            status: e.target.value,
                                                            date: getCurrDate()
                                                        }])}
                                                        options={possibleNextStatuses}/>

                                        <Button
                                            type="submit"
                                            fullWidth
                                            variant="contained"
                                            sx={{mt: 3, mb: 2}}
                                            disabled={isUpdateStatusLoading}
                                        >
                                            Update Status
                                        </Button>
                                    </Box> :
                                    <Button variant="contained" color="primary" sx={{mb: 2}} fullWidth disabled>Application
                                        Finalized</Button>
                            }
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box component="form" onSubmit={handleSaveAdminNotes} noValidate
                             sx={{mt: 1, mx: 'auto', maxWidth: 'sm'}}>
                            <Box>
                                <TextField
                                    id="notes"
                                    label="Admin Notes"
                                    multiline
                                    fullWidth
                                    rows={6}
                                    name="notes"
                                    onChange={(e) => setApplication(a => ({...a, adminNotes: e.target.value}))}
                                    value={application.adminNotes}
                                />
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    sx={{mt: 3, mb: 2}}
                                    disabled={isSaveAdminNotesLoading}
                                >
                                    Save
                                </Button>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </LoadingIndicator>
        </Container>
    )
}