export class User {
    static collectionName = 'profiles';

    constructor(data) {
        this.id = data.id ? data.id : '';
        this.email = data.email ? data.email : '';
        this.firstName = data.firstName ? data.firstName : '';
        this.lastName = data.lastName ? data.lastName : '';
        this.phone = data.phone ? data.phone : '';
        this.location = data.location ? data.location : null;
        this.roles = data.roles ? data.roles : '';
        this.salary = Array.isArray(data.salary) && data.salary.length === 2 ? data.salary.map(num => Number(num)) : null;
        this.hourlyRate = Array.isArray(data.hourlyRate) && data.hourlyRate.length === 2 ? data.hourlyRate.map(num => Number(num)) : null;
        this.workStyle = data.workStyle ? data.workStyle : '';
        this.experienceLevel = data.experienceLevel ? data.experienceLevel : '';
        this.photoURL = data.photoURL ? data.photoURL : '';
        this.linkedInURL = data.linkedInURL ? data.linkedInURL : '';
        this.portfolioURL = data.portfolioURL ? data.portfolioURL : '';
        this.userRole = data.userRole ? data.userRole : 'user';
        this.starredCareers = data.starredCareers ? data.starredCareers : [];
        this.appliedCareers = data.appliedCareers ? data.appliedCareers : [];
        this.preferredWorkTypes = data.preferredWorkTypes ? data.preferredWorkTypes : [];
        this.values = data.values ? data.values : [];
        this.resumes = data.resumes ? data.resumes : [];
        this.joinedAt = data.joinedAt ? data.joinedAt : null;
        this.isProfileComplete = data.isProfileComplete ? data.isProfileComplete : false;
    }
}