import React from "react"
import {Divider} from "@mui/material";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

export const OrDivider = () => {
    return (
        <Box sx={{display: 'flex', alignItems: 'center', width: '100%'}}>
            <Divider sx={{flexGrow: 1}}/>
            <Typography variant="caption" sx={{mx: 2, px: 1}}>
                Or
            </Typography>
            <Divider sx={{flexGrow: 1}}/>
        </Box>
    );
}