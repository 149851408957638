import React, {useEffect, useState} from 'react'
import {useHistory, useParams} from 'react-router-dom'
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import {CompanyManager} from "../../managers/CompanyManager";
import {RoutePath} from "../../utils/enums";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import {
    confirmedCompanyCareerFormLinkEmailTemplate,
    getCareerFormURL,
    isEmpty,
    logSentryException,
    sendGenericEmail
} from "../../utils/utils";
import isURL from "validator/es/lib/isURL";
import isEmail from "validator/es/lib/isEmail";
import {useSnackbar} from "../../contexts/SnackbarContext.js";
import {Company} from "../../entities/Company.js";

const CompanyForm = () => {
    const history = useHistory();
    const {id} = useParams();
    const {openSnackbar} = useSnackbar();
    const [isEdit, setIsEdit] = useState(false);
    const [errors, setErrors] = useState({})
    const [isFormSubmitting, setIsFormSubmitting] = useState(false);
    const [notifyPoC, setNotifyPoC] = useState(false);
    const [company, setCompany] = useState(new Company({}));

    useEffect(() => {
        setUp()
            .then()
            .catch((e) => {
                openSnackbar('Something went wrong: ' + e.message, 'warning')
                logSentryException(e);
            });
    }, []);

    const setUp = async () => {
        if (!id) {
            return;
        }

        const company = await CompanyManager.getById(id);

        if (company) {
            setCompany(company);
        }

        setIsEdit(true);
    }

    const handleSubmit = async (event) => {
        event.preventDefault();

        let formValidationErrors = {}
        try {
            setErrors({})
            setIsFormSubmitting(true);

            formValidationErrors = validateForm();

            if (Object.values(formValidationErrors).indexOf(true) >= 0) {
                throw Error;
            }

            const savedCompany = isEdit ?
                await CompanyManager.update(company) :
                await CompanyManager.create(company);

            history.push(RoutePath.COMPANY_VIEW_ALL);
            openSnackbar('Company has been created successfully.');

            await sendEmail(savedCompany);
        } catch (e) {
            const errorMessage = e.message ? e.message : 'Please check the form for errors.';
            setErrors(formValidationErrors);
            openSnackbar(errorMessage, 'error');
            logSentryException(e);
        } finally {
            setIsFormSubmitting(false);
        }
    };

    const sendEmail = async (savedCompany) => {
        try {
            if (notifyPoC) {
                const formURL = getCareerFormURL(savedCompany.id);

                await sendGenericEmail({
                    to: company.pocEmail,
                    subject: `Meaningful Careers: Welcome!`,
                    content: confirmedCompanyCareerFormLinkEmailTemplate(company.pocFullName, formURL)
                });

                openSnackbar('Company representative notified successfully.');
            }
        } catch (e) {
            openSnackbar('Notification for a company representative failed to send.', 'error');
            logSentryException(e);
        }
    }

    const validateForm = () => {
        const errors = {};

        errors.name = isEmpty(company.name);
        errors.websiteUrl = !isURL(company.websiteUrl);
        errors.shortDescription = isEmpty(company.shortDescription);
        errors.pocFullName = isEmpty(company.pocFullName);
        errors.pocEmail = !isEmail(company.pocEmail);

        return errors;
    }

    return (
        <Container maxWidth="xs" style={{marginTop: "30px"}}>
            <CssBaseline/>
            <Typography
                component="h3"
                variant="h4"
                align="center"
                color="text.primary"
                gutterBottom
            >
                {isEdit ? 'Edit Company' : 'Add New Company'}
            </Typography>

            <Box component="form" onSubmit={handleSubmit} noValidate sx={{mt: 1}}>
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="name"
                    label="Company Name"
                    name="name"
                    error={errors.name}
                    onChange={(e) => setCompany({...company, name: e.target.value})}
                    value={company.name ? company.name : ''}
                />
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="shortDescription"
                    label="Short Description"
                    name="shortDescription"
                    autoComplete="Short Description"
                    error={errors.shortDescription}
                    onChange={(e) => setCompany({...company, shortDescription: e.target.value})}
                    value={company.shortDescription ? company.shortDescription : ''}
                />
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="websiteUrl"
                    label="Website URL"
                    name="websiteUrl"
                    autoComplete="Website URL"
                    type='url'
                    error={errors.websiteUrl}
                    onChange={(e) => setCompany({...company, websiteUrl: e.target.value})}
                    value={company.websiteUrl ? company.websiteUrl : ''}
                />
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="pocFullName"
                    label="Point of Contact Full Name"
                    name="pocFullName"
                    autoComplete="Point of Contact Full Name"
                    error={errors.pocFullName}
                    onChange={(e) => setCompany({...company, pocFullName: e.target.value})}
                    value={company.pocFullName ? company.pocFullName : ''}
                />
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    type='email'
                    id="pocEmail"
                    label="Point of Contact Email"
                    name="pocEmail"
                    autoComplete="Point of Contact Email"
                    error={errors.pocEmail}
                    onChange={(e) => setCompany({...company, pocEmail: e.target.value})}
                    value={company.pocEmail ? company.pocEmail : ''}
                />
                {
                    !isEdit &&
                    <FormControlLabel control={<Checkbox
                        checked={notifyPoC}
                        onChange={() => setNotifyPoC(!notifyPoC)}
                        inputProps={{'aria-label': 'controlled'}}
                    />} label="Notify Company Representative"/>
                }

                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{mt: 3, mb: 2}}
                    disabled={isFormSubmitting}
                >
                    {isEdit ? 'Update' : 'Save'}
                </Button>
            </Box>
        </Container>
    )
}

export default CompanyForm