import * as React from 'react';
import {Verified} from "@mui/icons-material";

export default function EmployerFeatures() {
    return (
        <section className="core-features-wrapper section-padding mtm-30">
            <div className="container">
                <div className="col-xl-8 offset-xl-2 text-center">
                    <div className="block-contents">
                        <div className="section-title">
                            <h2 style={{marginTop: '30px'}}>Why Choose Us?</h2>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-md-6 col-xl-3 col-12" style={{display: 'flex', flexDirection: 'column'}}>
                        <div className="single-core-feature" style={{flexGrow: 1}}>
                            <div className="icon">
                                <img src="/images/icons/diversity.svg" alt=""/>
                            </div>
                            <div className="content">
                                <h3>Deep Industry Expertise</h3>
                                <p>With over 30 years of experience, we know your industry inside and out. We understand your hiring challenges and connect you with the right people for your needs.</p>
                            </div>
                        </div>
                    </div>
                     <div className="col-md-6 col-xl-3 col-12" style={{display: 'flex', flexDirection: 'column'}}>
                        <div className="single-core-feature" style={{flexGrow: 1}}>
                            <div className="icon">
                                <img src="/images/icons/global.svg" alt=""/>
                            </div>
                            <div className="content">
                                <h3>Streamlined Hiring Process</h3>
                                <p>Our AI platform cuts your time-to-hire by up to 30%. From posting jobs to making offers, we make the process smooth and efficient—saving you time and resources so you can focus on what matters.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-xl-3 col-12" style={{display: 'flex', flexDirection: 'column'}}>
                        <div className="single-core-feature" style={{flexGrow: 1}}>
                            <div className="icon">
                                <img src="/images/icons/personalized.svg" alt=""/>
                            </div>
                            <div className="content">
                                <h3>Personalized Support</h3>
                                <p>We’re more than just a platform; we’re your hiring partner. Our team provides personalized support at every step, including help with global hiring and in-house immigration services, so you can find the best talent from anywhere in the world.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-xl-3 col-12" style={{display: 'flex', flexDirection: 'column'}}>
                        <div className="single-core-feature" style={{flexGrow: 1}}>
                            <div className="icon">
                                <Verified fontSize="large" sx={{fontSize: '120px', color: '#8B0E3A'}}/>
                            </div>
                            <div className="content">
                                <h3>Proven Results</h3>
                                <p>We’ve helped organizations across North America build strong, diverse teams. Whether you need specialized talent or an entire team, MC delivers the results you’re looking for.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
