import * as React from 'react';
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {adminPalette} from "../utils/theme.js";
import Box from "@mui/material/Box";

export const EmployerConfirmationPage = () => {
    return (
        <Grid container justifyContent="center" sx={{
            height: '100vh',
            overflowX: 'hidden',
            overflow: 'hidden',
            m: 0,
            p: 0
        }}>
            <Grid item xs={12}
                  container
                  direction="column"
                  justifyContent="center"
                  sx={{
                      width: '100%',
                      backgroundColor: adminPalette.main,
                      position: 'relative',
                  }}>
                <Box sx={{
                    height: {xs: '50%', md: '40%'},
                    width: {xs: '100%', md: '60%'},
                    mx: 'auto',
                    zIndex: '0',
                    background: "url('/images/background-box-girl.png') no-repeat center center/contain"
                }}/>
                <Box sx={{mx: 'auto', width: '70%', mt: 2, zIndex: '10'}}>
                    <Typography variant="h4"
                                sx={{
                                    fontWeight: 'bold',
                                    color: 'white',
                                    textAlign: 'center',
                                    fontSize: '2.3rem'
                                }}
                                gutterBottom>
                        Thank you for filling out the form!
                    </Typography>
                    <Typography paragraph
                                sx={{
                                    color: 'white',
                                    fontWeight: 100,
                                    textAlign: 'center',
                                }}>
                        We'll be in touch shortly.
                    </Typography>
                </Box>
            </Grid>
        </Grid>);
};