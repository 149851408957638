import * as React from "react";
import {styled} from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import CheckCircle from "@mui/icons-material/CheckCircle";
import {Pending} from "@mui/icons-material";
import {Tooltip} from "@mui/material";
import {finalJobStatuses} from "../../utils/enums";
import {useAuth} from "../../contexts/AuthContext.js";
import {adminPalette, userPalette} from "../../utils/theme.js";

const CustomConnector = ({isShortVersion, ...props}) => {
    const dashLength = '10px';
    const gapLength = '20px';
    const lineColor = '#ABABAB'

    const style = {
        height: '2px',
        backgroundColor: isShortVersion ? 'transparent' : lineColor,
        borderTop: isShortVersion ? `2px dashed ${lineColor}` : lineColor,
        position: 'absolute',
        top: 10,
        left: "calc(-50% + 16px)",
        right: "calc(50% + 16px)",
        borderImage: isShortVersion ? `repeating-linear-gradient(to right, ${lineColor} 0, ${lineColor} ${dashLength}, transparent ${dashLength}, transparent ${gapLength}) 1 round` : 'none'
    };

    return <div style={style} {...props} />;
};

const CustomStepIconRoot = styled("div")(({iconColor, ownerState}) => ({
    color: "#EAEAF0",
    display: "flex",
    height: 22,
    alignItems: "center",
    "& .CustomStepIcon-completedIcon": {
        color: iconColor, zIndex: 1, fontSize: 28
    },
    "& .CustomStepIcon-progressIcon": {
        color: iconColor, zIndex: 1, fontSize: 28
    },
    "& .CustomStepIcon-circle": {
        width: 8, height: 8, borderRadius: "50%", backgroundColor: "blue"
    }
}));

function CustomStepIcon(props, date) {
    const {active, completed, className} = props;
    const {isAdmin} = useAuth();

    return <CustomStepIconRoot iconColor={isAdmin() ? adminPalette.main : userPalette.main}
                               ownerState={{active}}
                               className={className}>
        <Tooltip title={date} placement="top">
            {completed ? (<CheckCircle className="CustomStepIcon-completedIcon"/>) : (
                <Pending className="CustomStepIcon-progressIcon"/>)}
        </Tooltip>
    </CustomStepIconRoot>;
}

export const ApplicationStatusStepper = ({steps, isShortVersion = false}) => {
    const lastStep = steps.length > 0 ? steps[steps.length - 1].status : null;
    const isStatusFinalized = finalJobStatuses.indexOf(lastStep) >= 0;

    const stepsToShow = isShortVersion && steps.length >= 2 ? [steps[0], steps[steps.length - 1]] : steps;

    return <Stack sx={{
        padding: 2,
        width: "100%",
        maxWidth: '100vw',
        display: 'flex',
        flexWrap: 'nowrap',
        overflowX: 'auto',
        // '&::-webkit-scrollbar': {
        //     display: 'none' // Optionally hide the scrollbar
        // }
    }}>
        <Stepper
            alternativeLabel
            activeStep={isStatusFinalized ? stepsToShow.length : stepsToShow.length - 1}
            connector={<CustomConnector isShortVersion={isShortVersion}/>}>
            {stepsToShow.map(({status, date}) => (
                <Step key={status} sx={{minWidth: '100px'}}>
                    <StepLabel StepIconComponent={(props) => CustomStepIcon(props, date)}>
                        {status}
                    </StepLabel>
                </Step>
            ))}
        </Stepper>
    </Stack>;
}
