import React, {useEffect, useState} from 'react';
import {Button, Typography} from '@mui/material';
import {useAuth} from "../../contexts/AuthContext.js";
import {UserManager} from "../../managers/UserManager.js";
import {UploadedDocumentFile} from "./UploadedDocumentFile.js";
import {useSnackbar} from "../../contexts/SnackbarContext.js";
import {logSentryException} from "../../utils/utils.js";

export const ResumeSelector = ({selectedResume = undefined, setSelectedResume = undefined, error = false}) => {
    const {authenticatedUser, setAuthenticatedUser} = useAuth();
    const {openSnackbar} = useSnackbar();
    const [isLoading, setIsLoading] = useState(false);
    const isSelectionEnabled = selectedResume !== undefined && setSelectedResume !== undefined;

    const handleFileChange = async (event) => {
        try {
            setIsLoading(true);
            const selectedFile = event.target.files[0];
            const uploadedResume = await UserManager.uploadDocument(selectedFile);
            const updatedUser = {...authenticatedUser, resumes: [...authenticatedUser.resumes, uploadedResume]}

            await UserManager.update(updatedUser);
            setAuthenticatedUser(updatedUser);
            openSnackbar('Resume uploaded successfully.');
        } catch (e) {
            openSnackbar('Failed to upload a file. ' + e.toString().replace('FirebaseError: Firebase:', ''), 'error');
            logSentryException(e);
        } finally {
            setIsLoading(false);
            event.target.value = null;
        }
    };

    const removeResumeFromUserProfile = async (resumeId) => {
        try {
            setIsLoading(true)

            const updatedUser = {
                ...authenticatedUser,
                resumes: authenticatedUser.resumes.filter((resume) => resume.id !== resumeId)
            };

            await UserManager.update(updatedUser);
            setAuthenticatedUser(updatedUser);
            openSnackbar('Resume removed successfully.');
        } catch (e) {
            openSnackbar('Failed to remove resume. ' + e.toString().replace('FirebaseError: Firebase:', ''),'error');
            logSentryException(e);
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        if (isSelectionEnabled && authenticatedUser.resumes.length > 0) {
            setSelectedResume(authenticatedUser.resumes[0])
        } else if (isSelectionEnabled) {
            setSelectedResume(null);
        }
    }, [authenticatedUser.resumes]);

    return (
        <div style={{textAlign: 'center'}}>
            <Typography variant='h5' color={ error ? 'error' : 'textPrimary' } sx={{marginTop: 2, fontWeight: 'bold'}}>Resumes*</Typography>
            { error && <Typography variant='p' color='error' style={{ fontSize: '12px' }}>At least one resume is required.</Typography>}
            {
                authenticatedUser.resumes.length < 3 ?
                    <div style={{marginTop: 10}}>
                        <input
                            accept=".pdf"
                            style={{display: 'none'}}
                            id="resume-upload"
                            type="file"
                            onChange={handleFileChange}
                            disabled={isLoading || authenticatedUser.resumes.length >= 3}
                        />
                        <label htmlFor="resume-upload">
                            <Button component="span"
                                    variant="outlined"
                                    sx={{width: '100%'}}
                                    disabled={isLoading || authenticatedUser.resumes.length >= 3}>
                                Upload Resume
                            </Button>
                        </label>
                        <Typography sx={{fontSize: '.8rem'}}>(pdf - 2 MB max)</Typography>
                    </div> :
                    <Typography sx={{marginTop: 1}}>You can have maximum 3 resumes uploaded. Remove existing to upload
                        more.</Typography>
            }
            {
                authenticatedUser.resumes.length > 0 &&
                authenticatedUser.resumes.map((resume) =>
                    <UploadedDocumentFile key={`${resume.id}-resume`}
                                          backgroundColor={isSelectionEnabled && (selectedResume && selectedResume.id) === resume.id ? "#d7d7d7" : "white"}
                                          file={resume}
                                          onClick={() => isSelectionEnabled && setSelectedResume(resume)}
                                          onDelete={async () => await removeResumeFromUserProfile(resume.id)}/>)
            }
        </div>
    );
}