import {
    collection,
    doc,
    getCountFromServer,
    getDoc,
    getDocs,
    limit,
    query,
    serverTimestamp,
    setDoc,
    startAfter,
    where
} from "firebase/firestore";
import {db} from "../firebase-config.js";
import {Application} from "../entities/Application.js";
import {finalJobStatuses, JobStatus} from "../utils/enums.js";
import {getCurrDate} from "../utils/utils.js";
import {CareerManager} from "./CareerManager.js";
import {UserManager} from "./UserManager.js";
import {RESULTS_PER_PAGE} from "../utils/constants.js";

export class ApplicationManager {
    static async getAll(id, showArchived, type, lastDoc) {
        const constraints = [
            where(type === 'user' ? 'userId' : 'careerId', "==", id),
            where("isFinalized", "==", showArchived),
            limit(RESULTS_PER_PAGE)
        ];

        if (lastDoc) {
            constraints.push(startAfter(lastDoc));
        }

        const q = query(collection(db, Application.collectionName), ...constraints);
        const snapshot = await getDocs(q);
        const docs = snapshot.docs;
        const data = [];

        for (const document of snapshot.docs) {
            const application = new Application({...document.data(), id: document.id});

            if (type === 'user') {
                application.career = await CareerManager.getById(application.careerId);
            } else if (type === 'career') {
                application.user = await UserManager.getById(application.userId);
            }

            data.push(application);
        }

        return {
            data,
            lastDoc: docs.length > 0 ? docs[docs.length - 1] : null,
        };
    }

    static async getById(id) {
        const document = await getDoc(doc(db, Application.collectionName, id));

        return new Application({...document.data(), id});
    }

    static async getByCareerAndUserIds(careerId, userId) {
        const applicationQuery = query(
            collection(db, Application.collectionName),
            where("userId", "==", userId),
            where("careerId", "==", careerId),
            limit(1)
        );
        const applicationSnapshot = await getDocs(applicationQuery);

        if (applicationSnapshot.docs.length > 0) {
            return new Application(applicationSnapshot.docs[0].data());
        }

        return null;
    }

    static async getAllForNotifyingCompany(careerId) {
        const applications = [];
        const applicationQuery = query(
            collection(db, Application.collectionName),
            where("careerId", "==", careerId),
            where("currStatus", "==", JobStatus.UNDER_REVIEW),
            where("isCompanyNotified", "==", false),
        );

        const applicationSnapshot = await getDocs(applicationQuery);

        for (let doc of applicationSnapshot.docs) {
            const application = new Application(doc.data());
            application.user = await UserManager.getById(application.userId);
            applications.push(application);
        }

        return applications;
    }

    static async getCounts(careerId) {
        const archivedQuery = query(
            collection(db, Application.collectionName),
            where('careerId', '==', careerId),
            where('isFinalized', '==', true),
        );
        const totalQuery = query(
            collection(db, Application.collectionName),
            where('careerId', '==', careerId),
        );

        const archivedSnapshot = await getCountFromServer(archivedQuery);
        const totalSnapshot = await getCountFromServer(totalQuery);

        const archivedCount = archivedSnapshot.data().count;
        const totalCount = totalSnapshot.data().count;

        return {
            archivedCount,
            activeCount: totalCount - archivedCount,
            totalCount
        }
    }

    static async create(application, file = null) {
        const existingApplication = await ApplicationManager.getByCareerAndUserIds(application.careerId, application.userId);

        if (existingApplication !== null) {
            throw Error('You have applied for this career already');
        }

        const initStatusHistory = [
            {status: JobStatus.NEW, date: getCurrDate()},
            {status: JobStatus.PENDING_REVIEW, date: getCurrDate()},
        ];

        const customId = application.careerId + '_' + application.userId;
        const data = {
            ...application,
            id: customId,
            statusHistory: initStatusHistory,
            submittedAt: serverTimestamp(),
            isFinalized: false,
            currStatus: JobStatus.PENDING_REVIEW,
        }

        return await setDoc(doc(db, Application.collectionName, customId), data);
    }

    static update(application) {
        const statusHistory = application.statusHistory;

        application.isFinalized = finalJobStatuses.indexOf(statusHistory[statusHistory.length - 1].status) !== -1;
        application.currStatus = statusHistory[statusHistory.length - 1].status;

        return setDoc(doc(db, Application.collectionName, application.id), {...application}, {merge: true});
    }
}