import TextField from "@mui/material/TextField";
import {InputAdornment} from "@mui/material";
import {AutoAwesome, Search} from "@mui/icons-material";
import React from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

export const SearchBar = ({
                              query,
                              setQuery,
                              onSearch,
                              showAILabel = true,
                              placeholder = ''
                          }) => {
    return <>
        <Stack direction="row" spacing={1} paddingTop={2}>
            <TextField
                id="search-input field"
                placeholder={placeholder}
                variant="outlined"
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <Search/>
                        </InputAdornment>
                    ),
                    style: {borderRadius: 50},
                }}
                fullWidth
                value={query}
                onInput={(e) => setQuery(e.target.value)}
                onKeyUp={async (e) => {
                    if (e.key === 'Enter') {
                        await onSearch()
                    }
                }}
                autoComplete="off"
            />
        </Stack>
        {
            showAILabel &&
            <Typography variant='caption' sx={{display: 'block', textAlign: 'center', width: '100%', mt: 1}}>
                <AutoAwesome sx={{verticalAlign: 'middle', fontSize: '1rem', color: 'primary.main'}}/> Smart
                AI-Powered Search
            </Typography>
        }
    </>;
}