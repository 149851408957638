import * as React from 'react';

export default function IntroVideo({isCandidate = true}) {
    return (
        <section className="our-best-features-wrapper section-padding">
            <div className="container">
                <div className="row">
                    <div className="video-wrapper">
                        <iframe
                            src={isCandidate ? "https://www.youtube.com/embed/gD82oGvoXYc?si=xjdZWbYcUAg39fCY" : "https://www.youtube.com/embed/QkRyfvrH7_Q?si=_CCjfnXbrsDsfXc9"}
                            title="YouTube video player" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            allowFullScreen></iframe>
                    </div>
                </div>
            </div>
        </section>
    );
}
