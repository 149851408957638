import React from "react";
import {Avatar} from "@mui/material";
import Typography from "@mui/material/Typography";
import {Business, Email, Paid, Phone, Place, Stairs, Work} from "@mui/icons-material";
import {formatPhoneNumber, formatSalary} from "../../utils/utils.js";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";

export const ApplicationCardContent = ({applicant, isShortVersion = true, sx}) => {
    return <Box sx={sx}>
        <Avatar alt='applicant-avatar'
                src={applicant.photoURL ? applicant.photoURL : ''}
                sx={{width: 80, height: 80, mx: 'auto', mb: 1}}/>
        <Typography gutterBottom variant="h5" fontWeight="fontWeightBold" textAlign="center">
            {applicant.firstName} {applicant.lastName}
        </Typography>
        <Typography gutterBottom variant='span' sx={{display: 'flex', alignItems: 'center'}}>
            <Email sx={{color: 'primary.main', mr: 1}}/>
            {applicant.email}
        </Typography>
        <Typography gutterBottom variant='span' sx={{display: 'flex', alignItems: 'center'}}>
            <Phone sx={{color: 'primary.main', mr: 1}}/>
            {formatPhoneNumber(applicant.phone)}
        </Typography>
        <Typography gutterBottom variant='span' sx={{display: 'flex', alignItems: 'center'}}>
            <Place sx={{color: 'primary.main', mr: 1}}/>
            {applicant.location}
        </Typography>
        {
            !isShortVersion &&
            <>
                <Typography gutterBottom variant="span" sx={{display: 'flex', alignItems: 'center'}}>
                    <Business sx={{color: 'primary.main', mr: 1}}/>
                    {applicant.roles}
                </Typography>
                <Typography gutterBottom variant="span" sx={{display: 'flex', alignItems: 'center'}}>
                    <Paid sx={{color: 'primary.main', mr: 1}}/>
                    {formatSalary(applicant.salary ? applicant.salary : applicant.hourlyRate, applicant.salary === null)}
                </Typography>
                <Typography gutterBottom variant="span" sx={{display: 'flex', alignItems: 'center'}}>
                    <Work sx={{color: 'primary.main', mr: 1}}/>
                    {applicant.workStyle}
                </Typography>
                <Typography gutterBottom variant="span" sx={{display: 'flex', alignItems: 'center'}}>
                    <Stairs sx={{color: 'primary.main', mr: 1}}/>
                    {applicant.experienceLevel}
                </Typography>
                <Typography gutterBottom variant="span" sx={{display: 'flex', alignItems: 'center', mt: 2}}>
                    Preferred Work Types:
                </Typography>
                {applicant.preferredWorkTypes && applicant.preferredWorkTypes.map(value => <Chip key={value}
                                                                                                 label={value}
                                                                                                 color='primary'
                                                                                                 sx={{m: .5}}/>)}
                <Typography gutterBottom variant="span" sx={{display: 'flex', alignItems: 'center', mt: 2}}>
                    Values:
                </Typography>
                {applicant.values && applicant.values.map(value => <Chip key={value} label={value} color='primary'
                                                                         sx={{m: .5}}/>)}
            </>
        }
    </Box>
}

// this.linkedInURL = data.linkedInURL ? data.linkedInURL : '';
// this.portfolioURL = data.portfolioURL ? data.portfolioURL : '';