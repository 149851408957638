import * as React from 'react';
import {useCallback, useEffect, useRef, useState} from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import {useHistory} from 'react-router-dom';
import {CareerManager} from "../../managers/CareerManager";
import {CareerType, RoutePath, SearchCategory} from "../../utils/enums";
import {useAuth} from "../../contexts/AuthContext";
import {CircularProgress, Tab, Tabs} from "@mui/material";
import {SearchBar} from "../universal/SearchBar.js";
import Grid from "@mui/material/Grid";
import {CareerCard} from "./CareerCard.js";
import {Career} from "../../entities/Career.js";
import {logSentryException, searchRecords} from "../../utils/utils.js";
import {Add} from "@mui/icons-material";
import {FloatingActionButton} from "../universal/FloatingActionButton.js";
import {useSnackbar} from "../../contexts/SnackbarContext.js";

const titles = ['Archived', 'Paused', 'Current', 'Pending']

export default function CareerList({listType = CareerType.APPROVED}) {
    const history = useHistory();
    const [careers, setCareers] = useState([]);
    const [query, setQuery] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [isSearchMode, setIsSearchMode] = useState(false);
    const [totalRecordCount, setTotalRecordCount] = useState(0);
    const [lastDoc, setLastDoc] = useState(null);
    const [nextPage, setNextPage] = useState(0);
    const {isAdmin, authenticatedUser} = useAuth();
    const {openSnackbar} = useSnackbar();

    const observer = useRef();

    const lastElementRef = useCallback(
        (node) => {
            const hasMoreRecords = careers.length < totalRecordCount;

            if (isLoading) {
                return;
            }

            if (observer.current) {
                observer.current.disconnect();
            }

            observer.current = new IntersectionObserver(entries => {
                if (entries[0].isIntersecting && hasMoreRecords && !isLoading) {
                    if (isSearchMode) {
                        searchCareers(nextPage).then().catch(e => {
                            openSnackbar(e.message, 'error')
                            logSentryException(e);
                        });
                    } else {
                        getCareers().then().catch(e => {
                            openSnackbar(e.message, 'error')
                            logSentryException(e);
                        });
                    }
                }
            });

            if (node) {
                observer.current.observe(node);
            }
        },
        [isLoading, careers.length < totalRecordCount]
    );

    const updateSelectedTab = (event, tabIdx) => {
        switch (tabIdx) {
            case CareerType.ARCHIVED.idx:
                history.push(RoutePath.CAREER_VIEW_ALL_ARCHIVED);
                return;
            case CareerType.PAUSED.idx:
                history.push(RoutePath.CAREER_VIEW_ALL_PAUSED);
                return;
            case CareerType.PENDING.idx:
                history.push(RoutePath.CAREER_VIEW_ALL_PENDING_APPROVAL);
                return;
            default:
                history.push(RoutePath.CAREER_VIEW_ALL);
                return;
        }
    }

    useEffect(() => {
        setUp()
            .then()
            .catch((e) => {
                openSnackbar('Something went wrong while loading career list.', 'error')
                logSentryException(e);
            })
            .finally(() => setIsLoading(false));
    }, [listType, authenticatedUser]);


    const setUp = async () => {
        const totalCount = await CareerManager.getTotalCount(listType.name);
        setTotalRecordCount(totalCount);
        setIsSearchMode(false);

        await getCareers(true);
    }

    const searchCareers = async (page = 0, isFresh = false) => {
        try {
            const existingCareers = page > 0 ? [...careers] : [];

            setIsLoading(true);
            setIsSearchMode(true);

            if (isFresh) {
                setCareers([]);
            }

            const result = await searchRecords({
                query: query,
                pageNumber: page,
                status: getCareerListStatus(),
                category: SearchCategory.CAREERS
            });
            const newRecords = result.data.data ? result.data.data.map((c) => (new Career(c))) : [];

            setCareers([...(isFresh ? [] : existingCareers), ...newRecords]);
            setTotalRecordCount(result.data.totalSize);
            setNextPage(page + 1);
        } catch (e) {
            openSnackbar(e.message, 'error');
            logSentryException(e);
        } finally {
            setIsLoading(false);
        }
    }

    const getCareers = async (isFresh = false) => {
        setIsLoading(true);

        const newRecords = await CareerManager.getAll(listType.name, isFresh ? null : lastDoc, isAdmin());
        setCareers(careers => [...(isFresh ? [] : careers), ...newRecords.data]);
        setLastDoc(newRecords.lastDoc);

        setIsLoading(false);
    }

    const getCareerListStatus = () => {
        if (!isAdmin()) {
            return CareerType.APPROVED.name;
        }

        return listType.name;
    }

    return (
        <main>
            <CssBaseline/>
            {
                isAdmin() &&
                <Box sx={{width: '100%', my: 3, mx: 'auto'}}>
                    <Box sx={{borderBottom: 1, borderColor: 'divider', mx: 'auto'}}>
                        <Tabs value={listType.idx} onChange={updateSelectedTab} aria-label="tabs" centered>
                            <Tab label="Archived"/>
                            <Tab label="Paused"/>
                            <Tab label="Current"/>
                            <Tab label="Pending"/>
                        </Tabs>
                    </Box>
                </Box>
            }
            <Container maxWidth="md">
                {
                    isAdmin() &&
                    <Box>
                        <Typography
                            variant="h3"
                            align="center"
                            color="text.primary"
                            gutterBottom
                        >
                            {titles[listType.idx]} Careers
                        </Typography>

                        <FloatingActionButton title='Add Career'
                                              onClick={() => history.push(RoutePath.CAREER_ADD)}
                                              icon={<Add/>}/>
                    </Box>
                }
                <SearchBar query={query}
                           setQuery={setQuery}
                           placeholder={isAdmin() ? 'Search records ...' : 'Search for your ideal career...'}
                           showAILabel={true}
                           onSearch={async () => {
                               await searchCareers(0, true);
                           }}/>
            </Container>
            <Container maxWidth="lg" sx={{mt: 5}}>
                {
                    !isAdmin() &&
                    <Typography align="center" color="text.primary"
                                sx={{fontWeight: 'bold', fontSize: '1.5rem', mb: 4}}>
                        More careers dropping soon!
                    </Typography>
                }
                <Grid container spacing={4} maxWidth="lg">
                    {
                        careers.map((career, idx) => (
                            <Grid item key={`career-grid-item-${idx}`} xs={12} sm={6} md={4}>
                                <CareerCard key={`career-card-${idx}`}
                                            ref={careers.length === idx + 1 ? lastElementRef : null}
                                            career={career}/>
                            </Grid>
                        ))
                    }
                    {
                        isLoading &&
                        <Box sx={{mx: 'auto', mt: 10}}>
                            <CircularProgress color='secondary' size={60}/>
                        </Box>
                    }
                </Grid>
            </Container>
        </main>
    );
}