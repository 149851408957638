import * as React from 'react';
import {useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import {SalaryRangeInputs} from "./SalaryRangeInputs.js";
import {DEFAULT_HOURLY_RANGE, DEFAULT_SALARY_RANGE} from "../../../utils/constants.js";

export default function SalarySelector({salary, setSalary, hourlyRate, setHourlyRate, error, hideError}) {
    const [tab, setTab] = useState(salary === null ? 'hourly' : 'yearly');
    const [cachedHourlyRate, setCachedHourlyRate] = useState(null);
    const [cachedSalary, setCachedSalary] = useState(null);

    useEffect(() => {
        if (salary === null) {
            setTab('hourly')

            if (hourlyRate === null) {
                setHourlyRate(DEFAULT_HOURLY_RANGE)
            }
        } else {
            setTab('yearly')
        }
    }, [salary, hourlyRate]);

    const handleChange = (_, selectedTab) => {
        const isHourlyTabSelected = selectedTab === 'hourly';

        hideError();
        setTab(selectedTab);
        setCachedHourlyRate(hourlyRate);
        setCachedSalary(salary);

        if (isHourlyTabSelected && !hourlyRate) {
            setHourlyRate(cachedHourlyRate ? cachedHourlyRate : DEFAULT_HOURLY_RANGE);
        } else if (!isHourlyTabSelected && !salary) {
            setSalary(cachedSalary ? cachedSalary : DEFAULT_SALARY_RANGE);
        }
    };

    return (
        <Box>
            <TabContext value={tab} sx={{mt: 2, mb: 1}}>
                <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                    <TabList onChange={handleChange} aria-label="Salary Tabs" centered>
                        <Tab label="Hourly Rate" value="hourly"/>
                        <Tab label="Yearly Rate" value="yearly"/>
                    </TabList>
                </Box>
                <TabPanel value="hourly">
                    <SalaryRangeInputs salary={hourlyRate ? hourlyRate : DEFAULT_HOURLY_RANGE}
                                       setSalary={setHourlyRate}
                                       isHourly={true}
                                       error={error}/>
                </TabPanel>
                <TabPanel value="yearly">
                    <SalaryRangeInputs salary={salary ? salary : DEFAULT_SALARY_RANGE}
                                       setSalary={setSalary}
                                       error={error}/>
                </TabPanel>
            </TabContext>
        </Box>
    );
}