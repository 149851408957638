import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import {useHistory} from "react-router-dom";
import * as React from "react";
import {forwardRef} from "react";
import {useAuth} from "../../contexts/AuthContext";
import {Tooltip} from "@mui/material";
import {RoutePath} from "../../utils/enums";
import {Star, StarOutline} from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import {UserManager} from "../../managers/UserManager";
import {useSnackbar} from "../../contexts/SnackbarContext.js";
import {CareerCardContent} from "./CareerCardContent.js";
import {CareerCardStatusButtons} from "./CareerCardStatusButtons.js";
import {CareerActionButtons} from "./CareerActionButtons.js";
import {MAX_STARRED_CAREER_COUNT} from "../../utils/constants.js";
import {logSentryException} from "../../utils/utils.js";

export const CareerCard = forwardRef(({career}, ref) => {
    const {isAdmin, isAuthenticated, authenticatedUser, setAuthenticatedUser} = useAuth();
    const {openSnackbar} = useSnackbar();
    const history = useHistory();
    const isStarredCareer = !!authenticatedUser && authenticatedUser.starredCareers && authenticatedUser.starredCareers.indexOf(career.id) >= 0;

    const starCareer = async (event) => {
        event.stopPropagation();

        try {
            if (!isAuthenticated()) {
                history.push(RoutePath.SIGN_IN);
            } else if (authenticatedUser.starredCareers.length >= MAX_STARRED_CAREER_COUNT) {
                openSnackbar(`You can have max ${MAX_STARRED_CAREER_COUNT} careers starred. Please unstar some careers to star new ones.`, 'warning');
            } else {
                const updatedUser = {
                    ...authenticatedUser,
                    starredCareers: [...authenticatedUser.starredCareers, career.id]
                };

                setAuthenticatedUser(updatedUser);
                await UserManager.update(updatedUser);

                openSnackbar(`'${career.title}' career has been added to your favorites!`);
            }
        } catch (e) {
            openSnackbar(e.message, 'error');
            logSentryException(e);
        }
    }

    const unstarCareer = async (event) => {
        event.stopPropagation();

        try {
            if (!authenticatedUser) {
                history.push(RoutePath.SIGN_IN);
                return;
            }

            const updatedUser = {
                ...authenticatedUser,
                starredCareers: authenticatedUser.starredCareers.filter(val => val !== career.id),
            };

            setAuthenticatedUser(updatedUser);
            await UserManager.update(updatedUser);

            openSnackbar(`'${career.title}' career has been removed from your favorites!`);
        } catch (e) {
            openSnackbar(e.message, 'error');
            logSentryException(e);
        }
    }

    return (
        <span ref={ref}>
            <Card sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                transition: 'background 0.2s ease-in-out',
                '&:hover': {
                    background: '#F5F5F5',
                },
            }} onClick={() => history.push(RoutePath.CAREER_VIEW(career.id))}>
                {
                    !isAdmin() &&
                    <CardActions sx={{justifyContent: 'right'}}>
                        {
                            isStarredCareer ?
                                <Tooltip title='Unstar a career' placement='top'>
                                    <IconButton aria-label="unstar"
                                                color="primary"
                                                onClick={unstarCareer}>
                                        <Star/>
                                    </IconButton>
                                </Tooltip> :
                                <Tooltip title='Star a career' placement='top'>
                                    <IconButton aria-label="star"
                                                color="primary"
                                                onClick={starCareer}>
                                        <StarOutline/>
                                    </IconButton>
                                </Tooltip>
                        }
                    </CardActions>
                }
                {
                    isAdmin() &&
                    <CardActions sx={{justifyContent: 'right'}}>
                        <CareerActionButtons career={career}/>
                    </CardActions>
                }
                <CareerCardContent career={career}/>
                {
                    !isAdmin() &&
                    <CareerCardStatusButtons career={career}/>
                }
            </Card>
        </span>
    )
})