import React, {useCallback, useEffect, useRef, useState} from 'react'
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import {Link, useParams} from 'react-router-dom';
import {useAuth} from "../../contexts/AuthContext";
import {ApplicationWithCareerDetailsCard} from "./ApplicationWithCareerDetailsCard";
import {ApplicationManager} from "../../managers/ApplicationManager";
import {ApplicationWithApplicantDetailsCard} from "./ApplicationWithApplicantDetailsCard";
import {CareerManager} from "../../managers/CareerManager";
import {RoutePath, SearchCategory} from "../../utils/enums";
import {CircularProgress, Tab, Tabs} from "@mui/material";
import {useSnackbar} from "../../contexts/SnackbarContext.js";
import {
    companyApplicationNotificationEmailTemplate,
    logSentryException,
    searchRecords,
    sendGenericEmail
} from "../../utils/utils.js";
import {FloatingActionButton} from "../universal/FloatingActionButton.js";
import {People, Send} from "@mui/icons-material";
import {Application} from "../../entities/Application.js";
import {SearchBar} from "../universal/SearchBar.js";
import {userPalette} from "../../utils/theme.js";

const ApplicationList = () => {
    const {careerId} = useParams();
    const {currentUser, isAdmin} = useAuth();
    const {openSnackbar} = useSnackbar();
    const [applications, setApplications] = useState([])
    const [career, setCareer] = useState({});
    const [isSendingEmail, setIsSendingEmail] = useState(false);
    const [currTabIdx, setCurrTabIdx] = useState(0);
    const [query, setQuery] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [isSearchMode, setIsSearchMode] = useState(false);
    const [totalRecordCount, setTotalRecordCount] = useState(0);
    const [lastDoc, setLastDoc] = useState(null);
    const [nextPage, setNextPage] = useState(0);
    const [counts, setCounts] = useState({
        archivedCount: 0,
        activeCount: 0,
        totalCount: 0
    });

    const observer = useRef();

    const lastElementRef = useCallback(
        (node) => {
            const hasMoreRecords = applications.length < totalRecordCount;

            if (isLoading) {
                return;
            }

            if (observer.current) {
                observer.current.disconnect();
            }

            observer.current = new IntersectionObserver(entries => {
                if (entries[0].isIntersecting && hasMoreRecords && !isLoading) {
                    if (isSearchMode) {
                        searchApplications(nextPage).then().catch(e => {
                            openSnackbar(e.message, 'error');
                            logSentryException(e);
                        });
                    } else {
                        getApplications().then().catch(e => {
                            openSnackbar(e.message, 'error');
                            logSentryException(e);
                        });
                    }
                }
            });

            if (node) {
                observer.current.observe(node);
            }
        },
        [isLoading, applications.length < totalRecordCount]
    );

    const updateSelectedTab = (event, currTabIdx) => setCurrTabIdx(currTabIdx);

    const sendEmail = async () => {
        try {
            setIsSendingEmail(true);

            const newApplications = await ApplicationManager.getAllForNotifyingCompany(careerId);

            if (newApplications.length <= 0) {
                openSnackbar('There are no new applications to send.', 'warning');
            } else {
                await sendGenericEmail({
                    to: career.company.pocEmail,
                    subject: `Meaningful Careers: Newly selected candidates for '${career.title}' career`,
                    content: companyApplicationNotificationEmailTemplate(career.company.pocFullName, career.title, newApplications)
                });

                for (const application of newApplications) {
                    await ApplicationManager.update({
                        ...application,
                        isCompanyNotified: true,
                        user: null,
                        career: null,
                    });
                }

                openSnackbar('A list of selected applications has been sent to a company representative successfully.');
            }
        } catch (e) {
            openSnackbar('A list of selected applications failed to send. Please try again.', 'error');
            logSentryException(e);
        } finally {
            setIsSendingEmail(false);
        }
    }

    useEffect(() => {
        setIsLoading(true);
        setIsSearchMode(false);

        setUp()
            .then()
            .catch((e) => {
                openSnackbar('Something went wrong while loading application list.', 'error');
                logSentryException(e);
            })
            .finally(() => setIsLoading(false));
    }, [careerId, currTabIdx]);

    const setUp = async () => {
        setApplications([]);

        if (isAdmin()) {
            const counts = await ApplicationManager.getCounts(careerId);
            setCounts(counts);
            setTotalRecordCount(currTabIdx === 1 ? counts.archivedCount : counts.activeCount);
        }

        await getApplications(true);
    };

    const searchApplications = async (page = 0, isFresh = false) => {
        try {
            setIsLoading(true);
            setIsSearchMode(true);

            if (isFresh) {
                setApplications([]);
            }

            const existingApplications = page > 0 ? [...applications] : [];
            const result = await searchRecords({
                query: query,
                pageNumber: page,
                isFinalized: currTabIdx === 1,
                category: SearchCategory.APPLICATIONS,
                careerId: careerId
            });
            const newRecords = result.data.data ? result.data.data.map((a) => (new Application(a))) : [];

            setApplications([...(isFresh ? [] : existingApplications), ...newRecords]);
            setTotalRecordCount(result.data.totalSize);
            setNextPage(page + 1);
        } catch (e) {
            openSnackbar(e.message, 'error');
            logSentryException(e);
        } finally {
            setIsLoading(false);
        }
    }

    const getApplications = async (isFresh = false) => {
        setIsLoading(true);

        let newRecords;

        if (careerId) {
            const retrievedCareer = await CareerManager.getById(careerId, true);
            setCareer(retrievedCareer);

            newRecords = await ApplicationManager.getAll(careerId, currTabIdx === 1, 'career', isFresh ? null : lastDoc);
        } else {
            newRecords = await ApplicationManager.getAll(currentUser.uid, currTabIdx === 1, 'user', isFresh ? null : lastDoc);
        }

        setApplications(applications => [...(isFresh ? [] : applications), ...newRecords.data]);
        setLastDoc(newRecords.lastDoc);

        setIsLoading(false);
    }

    return (
        <main>
            <CssBaseline/>
            <Box sx={{width: '100%', my: 3, mx: 'auto'}}>
                <Box sx={{borderBottom: 1, borderColor: 'divider', mx: 'auto'}}>
                    <Tabs value={currTabIdx} onChange={updateSelectedTab} aria-label="tabs" centered>
                        <Tab label="Current"/>
                        <Tab label="Archived"/>
                    </Tabs>
                </Box>
            </Box>
            <Container maxWidth="md">
                <Typography variant="h3" align="center" color="text.primary">
                    {currTabIdx ? 'Archived' : 'Current'} Applications
                </Typography>
                {
                    isAdmin() ?
                        <Typography
                            variant="h6"
                            align="center"
                            color="text.primary"
                            gutterBottom
                        >
                            for "{career.title}" career
                        </Typography> :
                        <Box align="center" sx={{mt: 2}}>
                            <Link
                                to={RoutePath.CAREER_VIEW_ALL}
                                style={{
                                    textDecoration: 'none',
                                    fontSize: '1.2rem',
                                    color: userPalette.main
                                }}>
                                Apply to more Careers
                            </Link>
                        </Box>
                }
                {
                    isAdmin() &&
                    <SearchBar query={query}
                               setQuery={setQuery}
                               placeholder={'Search records ...'}
                               showAILabel={true}
                               onSearch={async () => await searchApplications(0, true)}/>
                }
                {
                    isAdmin() &&
                    <Box sx={{margin: 'auto', width: 'fit-content', mt: 3}}>
                        <Typography gutterBottom variant="span" sx={{display: 'flex', alignItems: 'center'}}>
                            <People sx={{color: 'primary.main', mr: 1}}/>
                            Active applications: {counts.activeCount}
                        </Typography>
                        <Typography gutterBottom variant="span" sx={{display: 'flex', alignItems: 'center'}}>
                            <People sx={{color: 'primary.main', mr: 1}}/>
                            Archived applications: {counts.archivedCount}
                        </Typography>
                        <Typography gutterBottom variant="span" sx={{display: 'flex', alignItems: 'center'}}>
                            <People sx={{color: 'primary.main', mr: 1}}/>
                            Total applications: {counts.totalCount}
                        </Typography>
                    </Box>
                }
            </Container>
            <Container maxWidth="lg" sx={{mt: 5}}>
                {
                    (careerId && currTabIdx === 0) &&
                    <FloatingActionButton title='Send New Applications To Company'
                                          onClick={sendEmail}
                                          icon={<Send/>}
                                          isLoading={isSendingEmail}/>
                }
                {
                    <Grid container spacing={4} maxWidth="lg">
                        {
                            applications.map((application, idx) =>
                                <Grid item key={application.id} xs={12} sm={6} md={4}>
                                    {
                                        careerId ?
                                            <ApplicationWithApplicantDetailsCard
                                                ref={applications.length === idx + 1 ? lastElementRef : null}
                                                application={application}/> :
                                            <ApplicationWithCareerDetailsCard
                                                ref={applications.length === idx + 1 ? lastElementRef : null}
                                                application={application}/>
                                    }
                                </Grid>
                            )
                        }
                        {
                            isLoading &&
                            <Box sx={{mx: 'auto', mt: 10}}>
                                <CircularProgress color='secondary' size={60}/>
                            </Box>
                        }
                    </Grid>
                }
            </Container>
        </main>
    );
}

export default ApplicationList