import * as React from "react";
import Typography from "@mui/material/Typography";
import {Category, Hub, Paid, Place, Stairs, Work} from "@mui/icons-material";
import {formatLocation, formatSalary, truncateString} from "../../utils/utils.js";
import CardContent from "@mui/material/CardContent";

export const CareerCardContent = ({career, children}) => {
    return <CardContent sx={{flexGrow: 1}}>
        {children}
        <Typography gutterBottom variant="h5" fontWeight="fontWeightBold">
            {`${career.title}`}
        </Typography>
        <Typography gutterBottom variant="span" sx={{display: 'flex', alignItems: 'center'}}>
            <Hub sx={{color: 'primary.main', mr: 1}}/>
            {career.workType}
        </Typography>
        <Typography gutterBottom variant="span" sx={{display: 'flex', alignItems: 'center'}}>
            <Paid sx={{color: 'primary.main', mr: 1}}/>
            {formatSalary(career.salary ? career.salary : career.hourlyRate, career.salary === null)}
        </Typography>
        <Typography gutterBottom variant="span" sx={{display: 'flex', alignItems: 'center'}}>
            <Place sx={{color: 'primary.main', mr: 1}}/>
            {formatLocation(career.location, true)}
        </Typography>
        <Typography gutterBottom variant="span" sx={{display: 'flex', alignItems: 'center'}}>
            <Work sx={{color: 'primary.main', mr: 1}}/>
            {career.workSetting}
        </Typography>
        <Typography gutterBottom variant="span" sx={{display: 'flex', alignItems: 'center'}}>
            <Stairs sx={{color: 'primary.main', mr: 1}}/>
            {career.experienceLevel}
        </Typography>
        <Typography gutterBottom variant="span" sx={{display: 'flex', alignItems: 'center'}}>
            <Category sx={{color: 'primary.main', mr: 1}}/>
            {career.category}
        </Typography>
        <Typography sx={{mt: 1}}>
            {truncateString(career.shortDescription, 150)}
        </Typography>
    </CardContent>
}