import {
    collection,
    doc,
    getCountFromServer,
    getDoc,
    getDocs,
    limit,
    query,
    setDoc,
    startAfter,
    where
} from "firebase/firestore";
import {db} from "../firebase-config.js";
import {User} from "../entities/User.js";
import {getDownloadURL, getStorage, ref, uploadBytes} from "firebase/storage";
import {v4} from "uuid";
import {RESULTS_PER_PAGE} from "../utils/constants.js";

export class UserManager {
    static async getById(id) {
        const document = await getDoc(doc(db, User.collectionName, id));

        if (!document.exists()) {
            return null;
        }

        return new User({...document.data(), id});
    }

    static async getAll(lastDoc) {
        const constraints = [
            where('isProfileComplete', '==', true),
            limit(RESULTS_PER_PAGE)
        ];

        if (lastDoc) {
            constraints.push(startAfter(lastDoc));
        }

        const q = query(collection(db, User.collectionName), ...constraints);
        const snapshot = await getDocs(q);
        const docs = snapshot.docs;

        return {
            data: docs.map((document) => (new User({...document.data(), id: document.id}))),
            lastDoc: docs.length > 0 ? docs[docs.length - 1] : null,
        };
    }

    static async getTotalCount() {
        const constraints = [
            where('isProfileComplete', '==', true)
        ];

        const totalQuery = query(collection(db, User.collectionName), ...constraints);

        const totalSnapshot = await getCountFromServer(totalQuery);

        return totalSnapshot.data().count;
    }

    static create(user) {
        const userRef = doc(collection(db, User.collectionName), user.id);

        return setDoc(userRef, {...user});
    }

    static update(user) {
        return setDoc(doc(db, User.collectionName, user.id), {...user});
    }

    static async uploadProfilePicture(file) {
        // todo: minimize the size of the image
        const storage = getStorage();
        const fileName = `profile-pictures/${file.name + v4()}`
        const storageRef = ref(storage, fileName);

        await uploadBytes(storageRef, file)

        return await getDownloadURL(storageRef);
    }

    static async uploadDocument(file, isResume = true) {
        const storage = getStorage();
        const fileName = `${isResume ? 'resumes' : 'cover-letters'}/${file.name + v4()}`
        const storageRef = ref(storage, fileName);

        await uploadBytes(storageRef, file);

        const fileLink = await getDownloadURL(storageRef);

        return {id: fileName, name: file.name, url: fileLink, type: file.type};
    }
}