import * as React from 'react';
import {useState} from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import {Link, useHistory} from 'react-router-dom';
import {useAuth} from "../../contexts/AuthContext";
import {Logo} from "./Logo";
import {RoutePath} from "../../utils/enums";
import {Drawer, List, ListItem, ListItemButton, ListItemText, Menu} from "@mui/material";
import {Close, MenuOutlined} from "@mui/icons-material";
import {useSnackbar} from "../../contexts/SnackbarContext.js";
import {logSentryException} from "../../utils/utils.js";

const basicPages = [
    {name: 'Careers', path: RoutePath.CAREER_VIEW_ALL},
    {name: 'Applications', path: RoutePath.APPLICATION_VIEW_ALL},
];
const adminPages = [
    {name: 'Careers', path: RoutePath.CAREER_VIEW_ALL},
    {name: 'Companies', path: RoutePath.COMPANY_VIEW_ALL},
    {name: 'Profiles', path: RoutePath.PROFILE_VIEW_ALL},
];

const basicSettings = [
    {name: 'Profile', path: RoutePath.PROFILE_EDIT},
    {name: 'Starred Careers', path: RoutePath.CAREER_VIEW_ALL_STARRED}
];
const adminSettings = [];

export const Header = () => {
    const [navAnchorElement, setNavAnchorElement] = useState(null);
    const [userAnchorElement, setUserAnchorElement] = useState(null);
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const {logout, authenticatedUser, isAdmin} = useAuth();
    const history = useHistory();
    const pages = isAdmin() ? adminPages : basicPages;
    const settings = isAdmin() ? adminSettings : basicSettings;
    const {isAuthenticated} = useAuth();
    const {openSnackbar} = useSnackbar();

    const openNavMenu = (event) => setNavAnchorElement(event.currentTarget);
    const closeNavMenu = () => setNavAnchorElement(null);
    const openUserMenu = (event) => setUserAnchorElement(event.currentTarget);
    const closeUserMenu = () => setUserAnchorElement(null);

    const handleLogoutUser = async () => {
        try {
            await logout();
            history.push(RoutePath.CAREER_VIEW_ALL);
            closeUserMenu();
            openSnackbar('User logged out successfully.')
        } catch (e) {
            openSnackbar(e.message, 'error');
            logSentryException(e);
        }
    };

    const container = window !== undefined ? () => window.document.body : undefined;

    return (
        <AppBar position="static" sx={{backgroundColor: 'transparent', boxShadow: 'none'}}>
            <Container maxWidth="xl">
                <Toolbar disableGutters>
                    <Box sx={{display: {xs: 'none', md: 'flex'}}}>
                        <Logo/>
                    </Box>

                    <Box sx={{flexGrow: 1, ml: 2, display: {xs: 'none', md: 'flex'}}}>
                        {pages.map((page) => (
                            <Link
                                to={page.path}
                                style={{color: "white", textDecoration: 'none'}}
                                key={page.name}>
                                <Button
                                    onClick={closeNavMenu}
                                    sx={{my: 2}}
                                >
                                    {page.name}
                                </Button>
                            </Link>
                        ))}
                    </Box>

                    <Box sx={{flexGrow: 0, display: {xs: 'none', md: 'flex'}}}>
                        {authenticatedUser ?
                            <Box>
                                <Tooltip title="Open settings">
                                    <IconButton onClick={openUserMenu} sx={{p: 0}}>
                                        <Avatar
                                            alt='user avatar'
                                            src={authenticatedUser ? authenticatedUser.photoURL : ''}
                                        />
                                    </IconButton>
                                </Tooltip>
                                <Menu
                                    sx={{mt: '45px'}}
                                    id="menu-appbar"
                                    anchorEl={userAnchorElement}
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    keepMounted
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    open={!!userAnchorElement}
                                    onClose={closeUserMenu}
                                >
                                    {settings.map((setting) => (
                                        <Link
                                            to={setting.path}
                                            style={{color: "#424242", textDecoration: 'none'}}
                                            key={setting.name}>
                                            <MenuItem onClick={closeUserMenu}>
                                                <Typography textAlign="center">{setting.name}</Typography>
                                            </MenuItem>
                                        </Link>
                                    ))}
                                    <MenuItem key='logout' onClick={handleLogoutUser}>
                                        <Typography textAlign="center">Logout</Typography>
                                    </MenuItem>
                                </Menu>
                            </Box> :
                            <Button key='signin'
                                    sx={{my: 2}}
                                    onClick={() => history.push(RoutePath.SIGN_IN)}>
                                Sign in
                            </Button>
                        }
                    </Box>


                    {/* mobile app bar */}
                    <Box sx={{flexGrow: 1, display: {xs: 'flex', md: 'none'}}}>
                        <Logo/>
                    </Box>

                    <Box sx={{display: {xs: 'flex', md: 'none'}}}>
                        <IconButton
                            size="large"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"

                            aria-label="open drawer"
                            edge="start"
                            onClick={() => setIsDrawerOpen(true)}
                            sx={{mr: 2, display: {md: 'none'}}}
                        >
                            <MenuOutlined/>
                        </IconButton>
                        <Drawer
                            variant="temporary"
                            open={isDrawerOpen}
                            anchor='top'
                            ModalProps={{
                                keepMounted: true, // Better open performance on mobile.
                            }}
                            sx={{
                                display: {xs: 'flex', md: 'none'},
                                '& .MuiDrawer-paper': {boxSizing: 'border-box', height: '100vh'},
                            }}
                        >
                            <Box>
                                <Close sx={{float: 'right', mt: 4, mx: 3}} onClick={() => setIsDrawerOpen(false)}/>
                                <div style={{clear: 'both'}}></div>
                                <Logo styles={{mx: 'auto', mb: 2}}/>
                                <List>
                                    {[...pages, ...settings].map((menuItem) =>
                                        <ListItem key={menuItem.name} disablePadding>
                                            <ListItemButton
                                                sx={{textAlign: 'center'}}
                                                onClick={() => {
                                                    history.push(menuItem.path);
                                                    setIsDrawerOpen(false);
                                                }}>
                                                <ListItemText primary={menuItem.name}
                                                              sx={{textTransform: 'uppercase'}}/>
                                            </ListItemButton>
                                        </ListItem>
                                    )}
                                    <ListItem key='auth-menu' disablePadding>
                                        <ListItemButton
                                            sx={{textAlign: 'center'}}
                                            onClick={async () => {
                                                if (isAuthenticated()) {
                                                    await handleLogoutUser();
                                                    setIsDrawerOpen(false);
                                                } else {
                                                    history.push(RoutePath.SIGN_IN);
                                                    setIsDrawerOpen(false);
                                                }
                                            }}>
                                            <ListItemText primary={isAuthenticated() ? 'Log Out' : 'Sign In'}
                                                          sx={{textTransform: 'uppercase'}}/>
                                        </ListItemButton>
                                    </ListItem>
                                </List>
                            </Box>
                        </Drawer>
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    )
};
