import * as React from 'react';
import {MdDiversity2, MdDiversity3} from "react-icons/md";
import {VerifiedUser} from "@mui/icons-material";

export default function AboutSectionFeatures() {
    return (
        <div className="container section-padding mtm-50">
            <div className="row  d-flex justify-content-between">
                <div className="col-xxl-3 col-xl-4 col-md-6">
                    <div className="single-about-features">
                        <div className="custom-icon" style={{display: 'flex', marginBottom: '30px'}}>
                            <div className="icon purple" style={{margin: 'auto'}}>
                                <VerifiedUser sx={{fontSize: '120px'}}/>
                            </div>
                        </div>
                        <div className="content">
                            <h3>Trusted Expertise</h3>
                            <p>With years of experience in construction and healthcare recruitment, we understand the
                                unique needs of these industries and are committed to bridging the gap to meaningful
                                careers.</p>
                        </div>
                    </div>
                </div>
                <div className="col-xxl-3 col-xl-4 col-md-6">
                    <div className="single-about-features">
                        <div className="custom-icon" style={{display: 'flex', marginBottom: '30px'}}>
                            <div className="icon purple" style={{margin: 'auto'}}>
                                <MdDiversity2/>
                            </div>
                        </div>
                        <div className="content">
                            <h3>People First</h3>
                            <p>We put career seekers, clients, and partners at the heart of what we do. By listening
                                closely and responding to your needs, we make your success our top priority.</p>
                        </div>
                    </div>
                </div>
                <div className="col-xxl-3 col-xl-4 col-md-6">
                    <div className="single-about-features">
                        <div className="custom-icon" style={{display: 'flex', marginBottom: '30px'}}>
                            <div className="icon purple" style={{margin: 'auto'}}>
                                <MdDiversity3/>
                            </div>
                        </div>
                        <div className="content">
                            <h3>Driven by Purpose</h3>
                            <p>We believe in dreaming big and following through. With a focus on doing great work, we
                                keep things fun and creative while delivering real results.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
