import React, {useEffect, useState} from 'react'
import {useHistory, useParams} from 'react-router-dom'
import Container from '@mui/material/Container';
import CssBaseline from "@mui/material/CssBaseline";
import {UserManager} from "../../managers/UserManager";
import {LoadingIndicator} from "../universal/LoadingIndicator.js";
import {useSnackbar} from "../../contexts/SnackbarContext.js";
import {ApplicationCardContent} from "../application/ApplicationCardContent.js";
import Typography from "@mui/material/Typography";
import {userPalette} from "../../utils/theme.js";
import {Launch} from "@mui/icons-material";
import Box from "@mui/material/Box";
import {RoutePath} from "../../utils/enums.js";
import {logSentryException} from "../../utils/utils.js";

export const ProfileDetails = () => {
    const {id} = useParams();
    const {openSnackbar} = useSnackbar();
    const history = useHistory();
    const [profile, setProfile] = useState({})
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setUp()
            .then()
            .catch((e) => {
                openSnackbar('Something went wrong: ' + e.message, 'error');
                logSentryException(e);
            })
            .finally(() => setIsLoading(false));
    }, []);

    const setUp = async () => {
        const profile = await UserManager.getById(id);

        if (profile === null) {
            history.push(RoutePath.PROFILE_VIEW_ALL);
            openSnackbar('The record does not exist.', 'error');
        } else {
            setProfile(profile);
        }
    }

    return (
        <Container maxWidth="md" sx={{py: 5}}>
            <CssBaseline/>
            <LoadingIndicator isLoading={isLoading}>
                <ApplicationCardContent applicant={profile}
                                        isShortVersion={false}
                                        sx={{maxWidth: '300px', mx: 'auto'}}/>
                <Box sx={{maxWidth: '300px', mx: 'auto'}}>
                    <Typography gutterBottom variant="span" sx={{display: 'flex', alignItems: 'center', mt: 2}}>
                        Resumes:
                    </Typography>
                    {
                        Array.isArray(profile.resumes) && profile.resumes.map((resume, idx) =>
                            <>
                                <a href={resume.url ? resume.url : '#'}
                                   target="_blank"
                                   style={{textDecoration: 'none', color: userPalette.main, marginLeft: 5}}>
                                    View Resume {idx + 1} Here
                                    <Launch sx={{color: userPalette.main, ml: .5, fontSize: 'small'}}/>
                                </a>
                                <br/>
                            </>
                        )
                    }
                </Box>
            </LoadingIndicator>
        </Container>
    )
}