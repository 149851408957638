import {CircularProgress, Fab} from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import React from "react";

export const FloatingActionButton = ({title, onClick, icon, isLoading}) => {
    const key = title.toLowerCase().replace(/\s+/g, '-');

    return <Tooltip key={key} title={title} placement='top'>
        {
            !isLoading ?
                <Fab color="primary"
                     aria-label={key}
                     onClick={onClick}
                     sx={{position: 'fixed', bottom: 30, right: 30}}>
                    {icon}
                </Fab> :
                <Fab color="primary"
                     aria-label={key}
                     sx={{position: 'fixed', bottom: 30, right: 30}}
                     disabled>
                    {<CircularProgress color='primary' size={20}/>}
                </Fab>
        }
    </Tooltip>
}