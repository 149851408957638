import * as React from 'react';
import {AiFillStar} from "react-icons/ai";
import {MdPerson, MdPerson2, MdPerson4} from "react-icons/md";

export default function CandidateTestimonials() {
    return (
        <section className="testimonial-wrapper section-padding fix">
            <div className="container">
                <div className="col-lg-8 col-xl-6 offset-xl-3 col-12 offset-lg-2 text-center">
                    <div className="block-contents">
                        <div className="section-title wow fadeInDown" data-wow-duration="1.2s">
                            <h2>Reviews</h2>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-12 col-lg-4">
                        <div className="single-testimoinal-box">
                            <div className="custom-icon" style={{textAlign: 'center'}}>
                                <div className="icon purple small">
                                    <MdPerson2/>
                                </div>
                            </div>
                            <div className="feedback">
                                “I was frustrated with my job search until I found MC. The opportunities were easy to understand, and the application process was seamless. The team was incredibly supportive, guiding me every step of the way. Thanks to their supportive guidance, I secured a position that aligns perfectly with my career goals!”
                            </div>
                            <div className="client-info">
                                <div className="client-name">
                                    <h6>Jessica F.</h6>
                                    <span>Medical Assistant</span>
                                </div>
                            </div>
                            <div className="rating">
                                <AiFillStar className="icon-star"/>
                                <AiFillStar className="icon-star"/>
                                <AiFillStar className="icon-star"/>
                                <AiFillStar className="icon-star"/>
                                <AiFillStar className="icon-star"/>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 col-lg-4">
                        <div className="single-testimoinal-box">
                            <div className="custom-icon" style={{textAlign: 'center'}}>
                                <div className="icon purple small">
                                    <MdPerson4/>
                                </div>
                            </div>
                            <div className="feedback">
                                “MC made my job search easy. They genuinely care about their clients and ensure you have a meaningful experience. Apply, apply, apply—I highly recommend them!”
                            </div>
                            <div className="client-info">
                                <div className="client-name">
                                    <h6>Michael B.</h6>
                                    <span>Personal Support Worker</span>
                                </div>
                            </div>
                            <div className="rating">
                                <AiFillStar className="icon-star"/>
                                <AiFillStar className="icon-star"/>
                                <AiFillStar className="icon-star"/>
                                <AiFillStar className="icon-star"/>
                                <AiFillStar className="icon-star"/>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 col-lg-4">
                        <div className="single-testimoinal-box">
                            <div className="custom-icon" style={{textAlign: 'center'}}>
                                <div className="icon purple small">
                                    <MdPerson/>
                                </div>
                            </div>
                            <div className="feedback">
                                “MC simplified my job search. The platform is user-friendly, allowing me to search for jobs based on my specific criteria. I found a role that perfectly aligns with my skills, experience, and growth plan. It was a bonus that the team was incredibly friendly and supportive. I would highly recommend MC to anyone in the job market!”
                            </div>
                            <div className="client-info">
                                <div className="client-name">
                                    <h6>Adrian L.</h6>
                                    <span>Industrial Field Engineer</span>
                                </div>
                            </div>
                            <div className="rating">
                                <AiFillStar className="icon-star"/>
                                <AiFillStar className="icon-star"/>
                                <AiFillStar className="icon-star"/>
                                <AiFillStar className="icon-star"/>
                                <AiFillStar className="icon-star"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
