import {CareerType} from "../utils/enums.js";

export class Career {
    static collectionName = 'careers';

    constructor(data = {}) {
        this.id = data.id ? data.id : '';
        this.companyId = data.companyId ? data.companyId : '';
        this.title = data.title ? data.title : '';
        this.category = data.category ? data.category : '';
        this.shortDescription = data.shortDescription ? data.shortDescription : '';
        this.descriptionOfResponsibilities = data.descriptionOfResponsibilities ? data.descriptionOfResponsibilities : '';
        this.mustHaveQualifications = data.mustHaveQualifications ? data.mustHaveQualifications : '';
        this.niceToHaveQualifications = data.niceToHaveQualifications ? data.niceToHaveQualifications : '';
        this.benefits = data.benefits ? data.benefits : '';
        this.values = data.values ? data.values : [];
        this.interviewSteps = data.interviewSteps ? data.interviewSteps : '';
        this.salary = Array.isArray(data.salary) && data.salary.length === 2 ? data.salary.map(num => Number(num)) : null;
        this.hourlyRate = Array.isArray(data.hourlyRate) && data.hourlyRate.length === 2 ? data.hourlyRate.map(num => Number(num)) : null;
        this.teamStructure = data.teamStructure ? data.teamStructure : '';
        this.workSetting = data.workSetting ? data.workSetting : '';
        this.location = data.location ? this.formatLocation(data.location) : [];
        this.createdAt = data.createdAt ? data.createdAt : null;
        this.deletedAt = data.deletedAt ? data.deletedAt : null;
        this.status = data.status ? data.status : CareerType.APPROVED.name;
        this.experienceLevel = data.experienceLevel ? data.experienceLevel : '';
        this.workType = data.workType ? data.workType : '';

        this.company = null;
    }

    formatLocation(location) {
        // when bigquery search is implemented to replace vertex ai search, replace location.split(';') with [location]
        // it is related to the fact that vertex ai datastore stores location as a string: 'a;b;c'
        return Array.isArray(location) ? location : location.split(';');
    }
}