import * as React from 'react';
import {RoutePath} from "../../../utils/enums.js";
import {useHistory} from "react-router-dom";

export default function HomeFeatures() {
    const history = useHistory();

    return (
        <section className="our-best-features-wrapper section-padding">
            <div className="container">
                <div className="text-center">
                    <div className="block-contents">
                        <div className="section-title">
                            <p style={{marginTop: '30px', fontSize: '110%'}}>At MC, we’re changing how people find
                                meaningful careers in construction and healthcare. Everyone deserves a career they’re
                                passionate about, and every business needs the right people to thrive.
                            </p>
                            <p style={{fontSize: '110%'}}>Whether you’re searching for your next career move or the perfect hire, our AI-powered
                                platform and tailored support make the journey seamless.</p>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-md-6 col-xl-4 col-12" style={{display: 'flex', flexDirection: 'column'}}>
                        <div className="single-core-feature" style={{flexGrow: 1}}>
                            <div style={{
                                margin: 'auto',
                                height: '200px',
                                width: '200px',
                                backgroundImage: `url(${process.env.PUBLIC_URL}/images/candidate-card.png)`,
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                                backgroundRepeat: 'no-repeat'
                            }}>
                            </div>
                            <div className="content">
                                <h3 style={{marginTop: '20px'}}>For Candidates</h3>
                                <p><b>Ready to Find Your Meaningful Career?</b></p>
                                <p>Your next chapter starts here!</p>
                                <button className="btn" style={{
                                    backgroundColor: '#5C0E7B',
                                    color: 'white',
                                    fontWeight: '500',
                                    width: '190px',
                                    height: '60px',
                                    fontSize: '1.2rem',
                                    marginTop: '20px'
                                }} onClick={() => {
                                    history.push(RoutePath.CANDIDATE_HOME)
                                    window.scrollTo(0, 0);
                                }}>Get Started
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-xl-4 col-12" style={{display: 'flex', flexDirection: 'column'}}>
                        <div className="single-core-feature" style={{flexGrow: 1}}>
                            <div style={{
                                margin: 'auto',
                                height: '200px',
                                width: '200px',
                                backgroundImage: `url(${process.env.PUBLIC_URL}/images/employer-card.png)`,
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                                backgroundRepeat: 'no-repeat'
                            }}>
                            </div>
                            <div className="content">
                                <h3 style={{marginTop: '20px'}}>For Employers</h3>
                                <p><b>Let’s Build Your Dream Team Together!</b></p>
                                <p>Dive into an untapped talent pool bursting with potential!</p>
                                <button className="btn" style={{
                                    backgroundColor: '#8B0E3A',
                                    color: 'white',
                                    fontWeight: '500',
                                    width: '190px',
                                    height: '60px',
                                    fontSize: '1.2rem',
                                    marginTop: '20px'
                                }} onClick={() => {
                                    history.push(RoutePath.EMPLOYER_HOME)
                                    window.scrollTo(0, 0);
                                }}>Get Started
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
