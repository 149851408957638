import * as React from 'react';
import {RoutePath} from "../../../utils/enums.js";
import {useHistory} from "react-router-dom";

export default function EmployerHero() {
    const history = useHistory();

    return (
        <section className="hero-welcome-wrapper hero-1 employer">
            <div className="single-slide text-white">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-7 col-12 col-lg-10 offset-lg-1 offset-xl-0">
                            <div className="hero-contents">
                                <h1 style={{marginTop: '20px'}}>Hire Faster, Hire Better.</h1>
                                <p>
                                    At MC, we know that hiring top talent is key to your organization’s success. Our
                                    AI-powered platform and personalized support simplify the hiring process, helping
                                    you find the perfect fit for your team in no time.
                                </p>
                                <button className="btn" style={{
                                    backgroundColor: 'white',
                                    color: '#8B0E3A',
                                    fontWeight: '500',
                                    width: '190px',
                                    height: '60px',
                                    fontSize: '1.2rem',
                                    marginTop: '20px'
                                }}
                                        onClick={() => history.push(RoutePath.EMPLOYER_SIGN_UP)}>Get Started
                                </button>
                            </div>
                        </div>
                        <div className="col-xl-5 col-12 text-xl-end col-lg-10 offset-lg-1 offset-xl-0">
                            <div className="hero-mobile mt-5 mt-xl-0">
                                <img src="/images/background-employers.png" alt="employers background"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
