import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import React from "react";

export const CustomDropdown = ({label, error, value, options, onChange}) => {
    const id = label.toLowerCase().replace(' ', '-');

    return <FormControl sx={{marginTop: 2, marginBottom: 1, minWidth: 120}} fullWidth>
        <InputLabel
            id={`${id}-label`}
            error={error}
            required>{label}</InputLabel>
        <Select
            labelId={`${id}-label`}
            id={`${id}-select`}
            value={value}
            label={label}
            error={error}
            onChange={onChange}
        >
            {options.map(value => <MenuItem key={value} value={value}>{value}</MenuItem>)}
        </Select>
    </FormControl>;
}