import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import {Close, Visibility} from "@mui/icons-material";
import {CardMedia, IconButton, Tooltip, Typography} from "@mui/material";
import {truncateString} from "../../utils/utils.js";
import CardActions from "@mui/material/CardActions";
import React, {useState} from "react";
import {getFileIcon} from "../../utils/theme.js";
import {ConfirmationDialog} from "./dialogs/ConfirmationDialog.js";

export const UploadedDocumentFile = ({onClick, file, onDelete, backgroundColor = 'white'}) => {
    const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState(false);

    return <Card
        sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: 400,
            padding: "10px",
            marginTop: "20px",
            background: backgroundColor
        }}
        onClick={onClick}>
        <CardMedia
            component="img"
            sx={{width: 50}}
            image={getFileIcon(file.type)}
            title="file type"
        />
        <CardContent sx={{display: "flex"}}>
            <Tooltip
                title={file.name.length > 25 ? file.name : ""}
                placement="top">
                <Typography sx={{whiteSpace: 'nowrap'}}>
                    {truncateString(file.name)}
                </Typography>
            </Tooltip>
        </CardContent>
        <CardActions>
            <Tooltip key="view-tooltip" title="View file" placement="top">
                <IconButton
                    component="a"
                    target="_blank"
                    href={file.url}
                >
                    <Visibility/>
                </IconButton>
            </Tooltip>
            <Tooltip key="delete-tooltip" title="Delete file" placement="top">
                <IconButton onClick={() => setIsConfirmationDialogOpen(true)}>
                    <Close/>
                </IconButton>
            </Tooltip>
        </CardActions>
        <ConfirmationDialog open={isConfirmationDialogOpen}
                            onClose={(event) => {
                                event.stopPropagation()
                                setIsConfirmationDialogOpen(false)
                            }}
                            title={`Are you sure you want to remove '${file.name}' from your application?`}
                            contentText='This file will be kept in our system. This removal will not affect your past applications.'
                            onConfirmation={async () => {
                                await onDelete();
                                setIsConfirmationDialogOpen(false);
                            }}
                            color='error'/>
    </Card>;
}