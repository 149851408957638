import * as React from 'react';
import PageBanner from "./PageBanner.js";
import ContentSection from "./ContentSection.js";
import AboutSectionFeatures from "./AboutSectionFeatures.js";

export default function AboutPage() {
    return (
        <span className="styles" style={{overflowX: 'hidden'}}>
            <PageBanner
                title="About Us"
                content="The Untapped Talent Marketplace™."
            />
            <ContentSection/>
            <AboutSectionFeatures/>
        </span>
    );
}
