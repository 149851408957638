import React, {useEffect, useState} from 'react'
import {useHistory, useParams} from 'react-router-dom'
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Grid from "@mui/material/Grid";
import CssBaseline from "@mui/material/CssBaseline";
import {CareerManager} from "../../managers/CareerManager";
import {LoadingIndicator} from "../universal/LoadingIndicator.js";
import {useAuth} from "../../contexts/AuthContext.js";
import {Business, Category, Hub, Paid, Place, Share, Stairs, Work} from "@mui/icons-material";
import {CareerCardStatusButtons} from "./CareerCardStatusButtons.js";
import {ApplicationStatusStepper} from "../application/ApplicationStatusStepper.js";
import {Divider, Tooltip} from "@mui/material";
import {ApplicationManager} from "../../managers/ApplicationManager.js";
import {CareerActionButtons} from "./CareerActionButtons.js";
import {CareerType, RoutePath} from "../../utils/enums.js";
import {useSnackbar} from "../../contexts/SnackbarContext.js";
import {CompanyManager} from "../../managers/CompanyManager.js";
import {Career} from "../../entities/Career.js";
import {Company} from "../../entities/Company.js";
import Chip from "@mui/material/Chip";
import IconButton from "@mui/material/IconButton";
import {formatLocation, formatSalary, logSentryException} from "../../utils/utils.js";

const CareerDetails = () => {
    const {id} = useParams();
    const {openSnackbar} = useSnackbar();
    const history = useHistory();
    const {authenticatedUser, isAdmin, isAuthenticated} = useAuth();
    const [career, setCareer] = useState(new Career({}))
    const [application, setApplication] = useState(null)
    const [company, setCompany] = useState(new Company({}))
    const [loading, setLoading] = useState(true);
    const [isCopied, setIsCopied] = useState(false);

    useEffect(() => {
        let unsubscribe;

        setUp()
            .then(i => unsubscribe = i)
            .catch((e) => {
                history.push(RoutePath.CAREER_VIEW_ALL);
                openSnackbar('Something went wrong while retrieving career details. Please select a career from the listing to view it.', 'warning')
                logSentryException(e);
            })
            .finally(() => setLoading(false));

        return () => unsubscribe && unsubscribe();
    }, []);

    const setUp = async () => {
        const {career, unsubscribe} = await CareerManager.getByIdObservable(id, setCareer);

        if (isAdmin()) {
            const companyData = await CompanyManager.getById(career.companyId);
            setCompany(companyData);
        }

        if (isAuthenticated() && !isAdmin() && career) {
            const application = await ApplicationManager.getByCareerAndUserIds(career.id, authenticatedUser.id);
            setApplication(application);
        }

        return unsubscribe;
    }

    const separateLines = (text) => text.split('\n').map((line) => <span key={line.slice(0, 10)}>{line}<br/></span>);

    return (
        <Container maxWidth="md" sx={{py: 5}}>
            <CssBaseline/>
            <LoadingIndicator isLoading={loading}>
                {
                    application !== null &&
                    <>
                        <ApplicationStatusStepper steps={application.statusHistory}/>
                        <Divider sx={{my: 1}}/>
                    </>
                }
                <Typography gutterBottom variant="h4" fontWeight="fontWeightBold" textAlign="center">
                    {career.title}
                    {
                        career.status === CareerType.APPROVED.name &&
                        <Tooltip title={isCopied ? 'Link copied!' : 'Copy link'} placement='top'>
                            <IconButton aria-label="copy"
                                        color="primary"
                                        sx={{ml: .5}}
                                        onClick={() => {
                                            setIsCopied(true);
                                            navigator.clipboard.writeText(window.location.href);
                                            setTimeout(() => setIsCopied(false), 1000);
                                        }}>
                                <Share/>
                            </IconButton>
                        </Tooltip>
                    }
                </Typography>
                <Grid container spacing={2}>
                    <Grid item xs={8}>
                        <Typography gutterBottom variant="span" sx={{display: 'flex', alignItems: 'center'}}>
                            <Hub sx={{color: 'primary.main', mr: 1}}/>
                            {career.workType}
                        </Typography>
                        <Typography gutterBottom variant="span" sx={{display: 'flex', alignItems: 'center'}}>
                            <Paid sx={{color: 'primary.main', mr: 1}}/>
                            {formatSalary(career.salary ? career.salary : career.hourlyRate, career.salary === null)}
                        </Typography>
                        <Typography gutterBottom variant="span" sx={{display: 'flex', alignItems: 'center'}}>
                            <Category sx={{color: 'primary.main', mr: 1}}/>
                            {career.category}
                        </Typography>
                        <Typography gutterBottom variant="span" sx={{display: 'flex', alignItems: 'center'}}>
                            <Work sx={{color: 'primary.main', mr: 1}}/>
                            {career.workSetting}
                        </Typography>
                        <Typography gutterBottom variant="span" sx={{display: 'flex', alignItems: 'center'}}>
                            <Stairs sx={{color: 'primary.main', mr: 1}}/>
                            {career.experienceLevel}
                        </Typography>
                        <Typography gutterBottom variant="span" sx={{display: 'flex', alignItems: 'center'}}>
                            <Place sx={{color: 'primary.main', mr: 1}}/>
                            {formatLocation(career.location)}
                        </Typography>
                        {
                            isAdmin() &&
                            <Typography gutterBottom variant="span" sx={{display: 'flex', alignItems: 'center'}}>
                                <Business sx={{color: 'primary.main', mr: 1}}/>
                                {company.name}
                            </Typography>
                        }
                    </Grid>
                    <Grid item
                          xs={4}
                          container
                          justifyContent="flex-end"
                          alignItems="center">
                        {
                            !isAdmin() ?
                                <CareerCardStatusButtons career={career} redirectToApplyForm={true}/> :
                                <CareerActionButtons career={career}/>
                        }
                    </Grid>
                    <Grid item xs={12}>
                        <Typography gutterBottom variant="h6" fontWeight="fontWeightBold" sx={{mt: 2}}>
                            Short Description:
                        </Typography>
                        <Typography>
                            {separateLines(career.shortDescription)}
                        </Typography>

                        <Typography gutterBottom variant="h6" fontWeight="fontWeightBold" sx={{mt: 2}}>
                            You will:
                        </Typography>
                        <Typography>
                            {separateLines(career.descriptionOfResponsibilities)}
                        </Typography>

                        <Typography gutterBottom variant="h6" fontWeight="fontWeightBold" sx={{mt: 2}}>
                            Must-have qualifications:
                        </Typography>
                        <Typography>
                            {separateLines(career.mustHaveQualifications)}
                        </Typography>

                        <Typography gutterBottom variant="h6" fontWeight="fontWeightBold" sx={{mt: 2}}>
                            Nice-to-have qualifications:
                        </Typography>
                        <Typography>
                            {separateLines(career.niceToHaveQualifications)}
                        </Typography>

                        <Typography gutterBottom variant="h6" fontWeight="fontWeightBold" sx={{mt: 2}}>
                            Benefits:
                        </Typography>
                        <Typography>
                            {separateLines(career.benefits)}
                        </Typography>

                        <Typography gutterBottom variant="h6" fontWeight="fontWeightBold" sx={{mt: 2}}>
                            Interview Steps:
                        </Typography>
                        <Typography>
                            {separateLines(career.interviewSteps)}
                        </Typography>

                        <Typography gutterBottom variant="h6" fontWeight="fontWeightBold" sx={{mt: 2}}>
                            Team Structure:
                        </Typography>
                        <Typography>
                            {separateLines(career.teamStructure)}
                        </Typography>

                        <Typography gutterBottom variant="h6" fontWeight="fontWeightBold" sx={{mt: 2}}>
                            Values:
                        </Typography>
                        {career.values.map(value => <Chip key={value} label={value} color='primary'/>)}
                    </Grid>
                </Grid>
            </LoadingIndicator>
        </Container>
    )
}

export default CareerDetails