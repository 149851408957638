import Box from "@mui/material/Box";
import * as React from "react";
import {RoutePath} from "../../utils/enums.js";
import {Link} from "react-router-dom";
import {useAuth} from "../../contexts/AuthContext.js";

export const Logo = ({styles, isHomePage = false, isLink = true}) => {
    const {isAdmin} = useAuth();

    const homePageLogo = `url('/images/white-long-logo.png')`;
    const longLogo = `url('/images/${isAdmin() ? 'brown' : 'purple'}-logo-long.png')`;
    const shortLogo = ` url('/images/${isAdmin() ? 'brown' : 'purple'}-logo.png')`;

    const logo = <Box sx={{
        margin: 1,
        backgroundImage: isHomePage ? homePageLogo : {xs: longLogo, md: shortLogo},
        backgroundSize: "cover",
        backgroundPosition: "center",
        width: isHomePage ? 100 : {xs: 115, md: 65},
        height: isHomePage ? 56 : 65,
        ...styles
    }}/>

    return (
        isLink ?
            <Link to={RoutePath.CAREER_VIEW_ALL}>{logo}</Link> :
            logo
    )
}