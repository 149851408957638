import {createTheme} from "@mui/material/styles";

export const getFileIcon = (type) => {
    return '/images/pdf.png';
}

export const userPalette = {
    main: '#5C0E7B',
    light: '#702299',
    dark: '#48005D',
    contrastText: '#fff',
}

export const adminPalette = {
    main: '#8B0E3A',
    light: '#C2185B',
    dark: '#6D071A',
    contrastText: '#fff',
}

// export const adminPalette = {
//     main: '#CC3035',
//     light: '#D35D5C',
//     dark: '#BF404A',
//     contrastText: '#fff',
// }

export const theme = createTheme({
    palette: {
        primary: userPalette,
        secondary: adminPalette,
        // error:{},
        // warning:{},
        // info:{},
        // success:{},
    }
});