import React from 'react';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import {useAuth} from './contexts/AuthContext';
import {Header} from "./components/nav/Header";
import {Content} from "./components/Content";
import BasicFooter from "./components/nav/BasicFooter.js";
import Box from "@mui/material/Box";
import {adminPalette, userPalette} from "./utils/theme.js";
import {RoutePath} from "./utils/enums.js";
import {useLocation} from "react-router-dom";
import IntroNavbar from "./components/home/IntroNavbar.js";
import IntroFooter from "./components/home/IntroFooter.js";
import Preloader from "./components/home/Preloader.js";
import './styles/animate.css';
import './styles/bootstrap.min.css';
import './styles/icons.css';
import './styles/style.css';

export const AppContent = () => {
    const {isAdmin} = useAuth();
    const location = useLocation();

    const theme = createTheme({
        palette: {
            primary: isAdmin() ? adminPalette : userPalette,
            secondary: isAdmin() ? userPalette : adminPalette,
        },
        typography: {
            fontFamily: 'Montserrat, sans-serif',
        },
    })

    return (
        <ThemeProvider theme={theme}>
            <Box className="App"
                 sx={{
                     display: 'grid',
                     gridTemplateRows: 'auto 1fr auto',
                     minHeight: '100vh',
                 }}>
                {
                    (location.pathname === RoutePath.HOME || location.pathname === RoutePath.CANDIDATE_HOME || location.pathname === RoutePath.EMPLOYER_HOME || location.pathname === RoutePath.EMPLOYER_CONFIRMATION || location.pathname === RoutePath.ABOUT) ?
                        <IntroNavbar/> :
                        <Header/>
                }
                <Content/>
                {
                    location.pathname !== RoutePath.EMPLOYER_CONFIRMATION &&
                    (
                        (location.pathname === RoutePath.HOME || location.pathname === RoutePath.CANDIDATE_HOME || location.pathname === RoutePath.EMPLOYER_HOME || location.pathname === RoutePath.ABOUT) ?
                            <IntroFooter/> :
                            <BasicFooter/>
                    )
                }
            </Box>
            <Preloader/>
        </ThemeProvider>
    );
}
