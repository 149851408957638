import {Autocomplete} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import React from "react";
import {CheckBox, CheckBoxOutlineBlank} from "@mui/icons-material";

export const CustomAutocomplete = ({selectedValues, onChange, error = false, helperText, options, label}) => {
    const icon = <CheckBoxOutlineBlank fontSize="small"/>;
    const checkedIcon = <CheckBox fontSize="small"/>;

    return (
        <Autocomplete
            value={selectedValues ? selectedValues : []}
            onChange={onChange}
            multiple
            fullWidth
            sx={{ml: -.1, minWidth: 120}}
            id={label.toLowerCase()}
            options={options}
            disableCloseOnSelect
            getOptionLabel={(option) => option}
            renderOption={(props, option, {selected}) => {
                return <li {...props}>
                    <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{marginRight: 8}}
                        checked={selected}
                    />
                    {option}
                </li>
            }}
            renderInput={(params) => {
                return <TextField {...params}
                                  label={label}
                                  sx={{marginTop: 2, marginBottom: 1}}
                                  error={error}
                                  helperText={helperText}
                                  required/>;
            }}
        />
    );
}