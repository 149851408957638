import {CareerManager} from "../../managers/CareerManager.js";
import {CareerType, RoutePath} from "../../utils/enums.js";
import {Tooltip} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import {ApprovalRounded, Archive, Edit, Inventory, PauseCircle, PlayCircle, Sync} from "@mui/icons-material";
import * as React from "react";
import {useState} from "react";
import {useHistory} from "react-router-dom";
import {ConfirmationDialog} from "../universal/dialogs/ConfirmationDialog.js";
import {useSnackbar} from "../../contexts/SnackbarContext.js";
import {logSentryException} from "../../utils/utils.js";

export const CareerActionButtons = ({career}) => {
    const history = useHistory();
    const {openSnackbar} = useSnackbar();
    const [taskInProgress, setTaskInProgress] = useState(null); // archive, approve, pause, resume
    const [isArchiveConfirmationDialogOpen, setIsArchiveConfirmationDialogOpen] = useState(false);
    const [isApproveConfirmationDialogOpen, setIsApproveConfirmationDialogOpen] = useState(false);
    const [isPauseConfirmationDialogOpen, setIsPauseConfirmationDialogOpen] = useState(false);
    const [isResumeConfirmationDialogOpen, setIsResumeConfirmationDialogOpen] = useState(false);

    const archiveCareer = async (event) => {
        event.stopPropagation();

        try {
            setIsArchiveConfirmationDialogOpen(false);
            setTaskInProgress('archive');
            await CareerManager.update({...career, status: CareerType.ARCHIVED.name});
            openSnackbar('Career archived successfully. Changes can take up to 2 minutes to propagate.');
        } catch (e) {
            openSnackbar(e.message, 'error');
            setTaskInProgress(null);
            logSentryException(e);
        }
    };

    const approveCareer = async (event) => {
        event.stopPropagation();

        try {
            setIsApproveConfirmationDialogOpen(false);
            setTaskInProgress('approve');
            await CareerManager.update({...career, status: CareerType.APPROVED.name});
            openSnackbar('Career approved successfully. Changes can take up to 2 minutes to propagate.');
        } catch (e) {
            openSnackbar(e.message, 'error');
            setTaskInProgress(null);
            logSentryException(e);
        }
    };

    const pauseCareer = async (event) => {
        event.stopPropagation();

        try {
            setIsPauseConfirmationDialogOpen(false);
            setTaskInProgress('pause');
            await CareerManager.update({...career, status: CareerType.PAUSED.name});
            openSnackbar('Career paused successfully. Changes can take up to 2 minutes to propagate.');
        } catch (e) {
            openSnackbar(e.message, 'error');
            setTaskInProgress(null);
            logSentryException(e);
        }
    };

    const resumeCareer = async (event) => {
        event.stopPropagation();

        try {
            setIsResumeConfirmationDialogOpen(false);
            setTaskInProgress('resume');
            await CareerManager.update({...career, status: CareerType.APPROVED.name});
            openSnackbar('Career resumed successfully. Changes can take up to 2 minutes to propagate.');
        } catch (e) {
            openSnackbar(e.message, 'error');
            setTaskInProgress(null);
            logSentryException(e);
        }
    };

    const EditButton = <Tooltip key='edit-button' title='Edit' placement='top'>
        <IconButton aria-label="edit"
                    color="primary"
                    onClick={(event) => {
                        event.stopPropagation();
                        history.push(RoutePath.CAREER_EDIT(career.id))
                    }}>
            <Edit/>
        </IconButton>
    </Tooltip>;

    const ApplicationsButton = <Tooltip key='applications-button' title='Applications' placement='top'>
        <IconButton aria-label="applications"
                    color="primary"
                    onClick={(event) => {
                        event.stopPropagation();
                        history.push(RoutePath.APPLICATION_FOR_CAREER(career.id));
                    }}>
            <Inventory/>
        </IconButton>
    </Tooltip>;

    const ArchiveButton = <Tooltip key='archive-button' title='Archive' placement='top'>
        <IconButton aria-label="archive"
                    color="primary"
                    disabled={taskInProgress === 'archive'}
                    onClick={(event) => {
                        event.stopPropagation();
                        setIsArchiveConfirmationDialogOpen(true);
                    }}>
            {taskInProgress === 'archive' ? <Sync/> : <Archive/>}
        </IconButton>
    </Tooltip>

    const ApproveButton = <Tooltip key='approve-button' title='Approve' placement='top'>
        <IconButton aria-label="approve"
                    color="primary"
                    disabled={taskInProgress === 'approve'}
                    onClick={(event) => {
                        event.stopPropagation();
                        setIsApproveConfirmationDialogOpen(true);
                    }}>
            {taskInProgress === 'approve' ? <Sync/> : <ApprovalRounded/>}
        </IconButton>
    </Tooltip>;

    const PauseButton = <Tooltip key='pause-button' title='Stop Accepting Applications' placement='top'>
        <IconButton aria-label="pause"
                    color="primary"
                    disabled={taskInProgress === 'pause'}
                    onClick={async (event) => {
                        event.stopPropagation();
                        setIsPauseConfirmationDialogOpen(true);
                    }}>
            {taskInProgress === 'pause' ? <Sync/> : <PauseCircle/>}
        </IconButton>
    </Tooltip>;

    const ResumeButton = <Tooltip key='resume-button' title='Continue Accepting Applications' placement='top'>
        <IconButton aria-label="resume"
                    color="primary"
                    disabled={taskInProgress === 'resume'}
                    onClick={async (event) => {
                        event.stopPropagation();
                        setIsResumeConfirmationDialogOpen(true);
                    }}>
            {taskInProgress === 'resume' ? <Sync/> : <PlayCircle/>}
        </IconButton>
    </Tooltip>;

    const getAdminCardActionButtons = () => {
        if (career.status === CareerType.ARCHIVED.name) {
            return [ApplicationsButton];
        } else if (career.status === CareerType.PAUSED.name) {
            return [EditButton, ApplicationsButton, ResumeButton, ArchiveButton];
        } else if (career.status === CareerType.PENDING.name) {
            return [EditButton, ApproveButton];
        }

        return [EditButton, ApplicationsButton, PauseButton];
    }

    return <>
        {getAdminCardActionButtons()}

        <ConfirmationDialog open={isArchiveConfirmationDialogOpen}
                            onClose={(event) => {
                                event.stopPropagation()
                                setIsArchiveConfirmationDialogOpen(false)
                            }}
                            title={`Are you sure you want to archive '${career.title}' career?`}
                            contentText='This action is irrevocable. All associated applications must be finalized.'
                            onConfirmation={event => archiveCareer(event)}
                            color='error'/>
        <ConfirmationDialog open={isApproveConfirmationDialogOpen}
                            onClose={(event) => {
                                event.stopPropagation()
                                setIsApproveConfirmationDialogOpen(false)
                            }}
                            title={`Are you sure you want to approve '${career.title}' career?`}
                            contentText='This action is irrevocable. This job will become visible to career seekers.'
                            onConfirmation={event => approveCareer(event)}
                            color='error'/>
        <ConfirmationDialog open={isPauseConfirmationDialogOpen}
                            onClose={(event) => {
                                event.stopPropagation()
                                setIsPauseConfirmationDialogOpen(false)
                            }}
                            title={`Are you sure you want to pause '${career.title}' career?`}
                            contentText='This job will be paused and then hidden from career seekers.'
                            onConfirmation={event => pauseCareer(event)}
                            color='error'/>
        <ConfirmationDialog open={isResumeConfirmationDialogOpen}
                            onClose={(event) => {
                                event.stopPropagation()
                                setIsResumeConfirmationDialogOpen(false)
                            }}
                            title={`Are you sure you want to resume '${career.title}' career?`}
                            contentText='This job will be resumed and become available for applying.'
                            onConfirmation={event => resumeCareer(event)}
                            color='error'/>
    </>
}