import {Dialog, DialogContent, DialogTitle} from "@mui/material";
import Button from "@mui/material/Button";
import * as React from "react";
import {useState} from "react";
import Box from "@mui/material/Box";
import {useAuth} from "../../../contexts/AuthContext.js";
import {UserManager} from "../../../managers/UserManager.js";
import {useSnackbar} from "../../../contexts/SnackbarContext.js";
import {Save, Upload} from "@mui/icons-material";
import {logSentryException} from "../../../utils/utils.js";

export const ImageUploadDialog = ({open, onClose}) => {
    const [fileUpload, setFileUpload] = useState(null);
    const [imgPreviewSource, setImgPreviewSource] = useState(''); // new state for the preview source
    const [loading, setLoading] = useState(false)
    const {authenticatedUser, setAuthenticatedUser} = useAuth()
    const {openSnackbar} = useSnackbar();

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setFileUpload(file);
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setImgPreviewSource(reader.result); // set the result to previewSrc
            }
            reader.readAsDataURL(file);
        }
    };

    const updateProfilePicture = async () => {
        try {
            setLoading(true)

            const photoURL = await UserManager.uploadProfilePicture(fileUpload)
            const updatedUser = {...authenticatedUser, photoURL}
            await UserManager.update(updatedUser)
            setAuthenticatedUser(updatedUser);
            onClose();
            openSnackbar('Profile picture updated successfully.');
        } catch (e) {
            openSnackbar(e.message, 'error');
            logSentryException(e);
        }

        setLoading(false)
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            xs={{justifyContent: 'center'}}
        >
            <DialogTitle id="alert-dialog-title" style={{fontWeight: 'bold', textAlign: 'center'}}>
                Update Profile Picture
            </DialogTitle>
            <DialogContent>
                {
                    imgPreviewSource &&
                    <Box alt="Uploaded preview"
                         style={{
                             background: `url("${imgPreviewSource}") no-repeat center center/cover`,
                             width: '100px',
                             height: '100px',
                             borderRadius: '50%',
                             display: 'block',
                             margin: '0 auto'
                         }}
                    />
                }
                <input
                    accept=".jpg"
                    style={{display: 'none'}}
                    id="raised-button-file"
                    type="file"
                    onChange={handleFileChange}
                />
                <label htmlFor="raised-button-file" style={{width: '100%'}}>
                    <Button
                        sx={{mt: 2}}
                        variant="outlined"
                        component="span"
                        startIcon={<Upload/>}
                        fullWidth>
                        Select {imgPreviewSource && 'Another'} Image
                    </Button>
                </label>
                {
                    imgPreviewSource &&
                    <Button
                        sx={{mt: 2}}
                        variant="contained"
                        component="span"
                        color="primary"
                        onClick={async () => await updateProfilePicture()}
                        disabled={loading}
                        startIcon={<Save/>}
                        fullWidth>
                        Save
                    </Button>
                }
            </DialogContent>
        </Dialog>
    );
}
