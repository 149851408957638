import CardContent from "@mui/material/CardContent";
import Card from "@mui/material/Card";
import React, {forwardRef} from "react";
import {useHistory} from "react-router-dom";
import {RoutePath} from "../../utils/enums";
import {ApplicationCardContent} from "../application/ApplicationCardContent.js";

export const ProfileCard = forwardRef(({profile}, ref) => {
    const history = useHistory();

    return <Card ref={ref}
                 sx={{
                     background: '#FFF',
                     transition: 'background 0.2s ease-in-out',
                     '&:hover': {
                         background: '#F5F5F5',
                     },
                 }}
                 onClick={() => history.push(RoutePath.PROFILE_VIEW(profile.id))}>
        <CardContent sx={{flexGrow: 1}}>
            <ApplicationCardContent applicant={profile} sx={{mt: 1}}/>
        </CardContent>
    </Card>
})