import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import Button from "@mui/material/Button";
import * as React from "react";

export const ConfirmationDialog = ({open, onClose, title, contentText, onConfirmation, color}) => {
    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            xs={{justifyContent: 'center'}}
        >
            <DialogTitle id="alert-dialog-title">
                {title}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {contentText}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>No</Button>
                <Button color={color} onClick={onConfirmation} autoFocus>
                    Yes
                </Button>
            </DialogActions>
        </Dialog>
    );
}