import * as React from 'react';
import {useEffect, useState} from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import {CareerManager} from "../../managers/CareerManager";
import {CareerCard} from "./CareerCard";
import {useAuth} from "../../contexts/AuthContext";
import {LoadingIndicator} from "../universal/LoadingIndicator.js";
import {useSnackbar} from "../../contexts/SnackbarContext.js";
import {logSentryException} from "../../utils/utils.js";

export default function StarredCareerList() {
    const [careers, setCareers] = useState([])
    const [isLoading, setIsLoading] = useState(true);
    const {authenticatedUser} = useAuth();
    const {openSnackbar} = useSnackbar();

    useEffect(() => {
        let unsubscribe;

        setUp()
            .then((i) => unsubscribe = i)
            .catch((e) => {
                openSnackbar('Something went wrong while loading your starred careers.', 'error')
                logSentryException(e);
            })
            .finally(() => setIsLoading(false));

        return () => unsubscribe && unsubscribe();
    }, [authenticatedUser.starredCareers]);

    const setUp = async () => {
        return await CareerManager.getAllByIdsObservable(setCareers, authenticatedUser.starredCareers);
    }

    return (
        <main>
            <CssBaseline/>
            <Typography
                variant="h3"
                align="center"
                color="text.primary"
                gutterBottom
                sx={{pt: 8, pb: 6}}
            >
                Starred Careers
            </Typography>
            <Container maxWidth="lg">
                <LoadingIndicator isLoading={isLoading}>
                    {
                        careers.length <= 0 ?
                            <Typography align="center">You have not starred any careers yet.</Typography> :
                            <Grid container spacing={4} maxWidth="lg">
                                {
                                    careers.map((career) => (
                                        <Grid item key={career.id} xs={12} sm={6} md={4}>
                                            <CareerCard career={career}/>
                                        </Grid>
                                    ))
                                }
                            </Grid>
                    }
                </LoadingIndicator>
            </Container>
        </main>
    );
}