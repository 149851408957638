import React, {useState} from "react"
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import {Link, useHistory} from 'react-router-dom';
import {useAuth} from "../../contexts/AuthContext";
import {RoutePath} from "../../utils/enums";
import {OrDivider} from "../universal/OrDivider.js";
import {useSnackbar} from "../../contexts/SnackbarContext.js";
import {Google} from "@mui/icons-material";
import {logSentryException} from "../../utils/utils.js";

const SignIn = () => {
    const [email, setEmail] = useState()
    const [password, setPassword] = useState()
    const {login, loginWithGoogle} = useAuth()
    const [loading, setLoading] = useState(false)
    const history = useHistory()
    const {openSnackbar} = useSnackbar();

    const handleSubmit = async (event) => {
        event.preventDefault();

        try {
            setLoading(true)
            await login(email, password)
            history.goBack()
        } catch (e) {
            openSnackbar('Failed to sign in. ' + e.toString().replace('FirebaseError: Firebase:', ''), 'error')
            logSentryException(e);
        }

        setLoading(false)
    };

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline/>
            <Box
                component="form" onSubmit={handleSubmit} noValidate
                sx={{
                    mt: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Avatar sx={{m: 1, bgcolor:  'primary.main'}}>
                    <LockOutlinedIcon/>
                </Avatar>
                <Typography component="h1" variant="h4" sx={{ my: 1 }}>
                    Sign In
                </Typography>
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                    autoFocus
                    onChange={(e) => setEmail(e.target.value)}
                />
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                    onChange={(e) => setPassword(e.target.value)}
                />
                <Link to={RoutePath.RESET_PASSWORD}
                      variant="body2"
                      style={{textDecoration: 'none', alignSelf: 'flex-start', fontSize: '.9rem'}}>
                    Forgot password?
                </Link>
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{my: 2, textTransform: 'capitalize', fontSize: '1rem'}}
                    disabled={loading}
                >
                    Sign In
                </Button>
                <OrDivider/>
                <Button
                    fullWidth
                    variant="contained"
                    sx={{my: 2, textTransform: 'capitalize', fontSize: '1rem'}}
                    startIcon={<Google/>}
                    disabled={loading}
                    onClick={async () => {
                        await loginWithGoogle();
                    }}
                >
                    Sign In With Google
                </Button>
                <Link to={RoutePath.SIGN_UP} variant="body2" style={{textDecoration: 'none', fontSize: '1rem'}}>
                    Don't have an account? Sign up here
                </Link>
            </Box>
        </Container>
    )
}

export default SignIn