import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import CardActions from "@mui/material/CardActions";
import {useHistory} from "react-router-dom";
import * as React from "react";
import {forwardRef, useState} from "react";
import {useAuth} from "../../contexts/AuthContext";
import Button from "@mui/material/Button";
import {Tooltip} from "@mui/material";
import {RoutePath} from "../../utils/enums";
import {ContentCopy, Edit, Mail, Person} from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import {getCareerFormURL, truncateString} from "../../utils/utils.js";

export const CompanyCard = forwardRef(({company}, ref) => {
    const {isAdmin} = useAuth();
    const history = useHistory();
    const [isCopied, setIsCopied] = useState(false);

    return (
        <span ref={ref}>
            <Card sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
            }}>
                {
                    isAdmin() &&
                    <CardActions sx={{justifyContent: 'right'}}>
                        <Tooltip title='Edit' placement='top'>
                            <IconButton aria-label="edit"
                                        color="primary"
                                        onClick={(event) => {
                                            event.stopPropagation();
                                            history.push(RoutePath.COMPANY_EDIT(company.id))
                                        }}>
                                <Edit/>
                            </IconButton>
                        </Tooltip>
                    </CardActions>
                }
                <CardContent sx={{flexGrow: 1, pt: 0, display: 'flex', flexDirection: 'column'}}>
                    <Typography variant="h5" fontWeight="fontWeightBold" component='a'
                                href={company.websiteUrl}
                                sx={{textDecoration: 'none', color: 'black', mx: 'auto'}}
                                target='blank'>
                        {company.name}
                    </Typography>
                    <Tooltip title={isCopied ? 'Link copied!' : 'Copy link'} placement='right'>
                        <Button variant="contained"
                                size='small'
                                endIcon={<ContentCopy/>}
                                sx={{textTransform: 'none', borderRadius: 10, my: 2, width: 'fit-content', mx: 'auto'}}
                                onClick={() => {
                                    setIsCopied(true);
                                    navigator.clipboard.writeText(getCareerFormURL(company.id));
                                    setTimeout(() => setIsCopied(false), 1000);
                                }}>{company.id}</Button>
                    </Tooltip>
                    <Typography gutterBottom variant="span" sx={{display: 'flex', alignItems: 'center'}}>
                        <Person sx={{color: 'primary.main', mr: 1}}/>
                        {company.pocFullName}
                    </Typography>
                    <Typography gutterBottom variant="span" sx={{display: 'flex', alignItems: 'center'}}>
                        <Mail sx={{color: 'primary.main', mr: 1}}/>
                        {company.pocEmail}
                    </Typography>
                    <Typography sx={{mt: 1}}>
                        {truncateString(company.shortDescription, 150)}
                    </Typography>
                </CardContent>
            </Card>
        </span>
    )
})