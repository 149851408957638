import React, {useContext, useEffect, useState} from "react"
import {fbAuth as auth} from "../firebase-config"
import {
    createUserWithEmailAndPassword,
    GoogleAuthProvider,
    sendPasswordResetEmail,
    signInWithEmailAndPassword,
    signInWithPopup,
    updateEmail as sendUpdateEmail,
} from 'firebase/auth';
import {User} from "../entities/User";
import {UserManager} from "../managers/UserManager";
import {useSnackbar} from "./SnackbarContext.js";
import {useHistory} from "react-router-dom";
import {RoutePath} from "../utils/enums.js";
import {logSentryException} from "../utils/utils.js";
import {serverTimestamp} from "firebase/firestore";

const AuthContext = React.createContext({})

export function useAuth() {
    return useContext(AuthContext)
}

export function AuthProvider({children}) {
    const [currentUser, setCurrentUser] = useState()// firebase user
    const [authenticatedUser, setAuthenticatedUser] = useState();
    const [loading, setLoading] = useState(true)
    const {openSnackbar} = useSnackbar();
    const history = useHistory();

    const signup = async (email, password) => {
        await createUserWithEmailAndPassword(auth, email, password);
        await login(email, password);
    }

    const login = async (email, password) => {
        await signInWithEmailAndPassword(auth, email, password);
    }

    const loginWithGoogle = async () => {
        const provider = new GoogleAuthProvider();

        await signInWithPopup(auth, provider);
    }

    function logout() {
        return auth.signOut()
    }

    function resetPassword(email) {
        return sendPasswordResetEmail(auth, email)
    }

    function updateEmail(email) {
        return sendUpdateEmail(auth.currentUser, email)
    }

    function updatePassword(password) {
        return currentUser.updatePassword(password)
    }

    const isAdmin = () => authenticatedUser && authenticatedUser.userRole === 'admin';

    const isAuthenticated = () => !!authenticatedUser;

    useEffect(() => {
        try {
            if (!auth) {
                openSnackbar('Something went wrong while loading the website. Website admin has been notified.', 'error');
                return;
            }

            auth.onAuthStateChanged(async (user) => {
                try {
                    setCurrentUser(user)

                    if (user == null) {
                        setAuthenticatedUser(null);
                        setLoading(false);
                        return;
                    }

                    let existingUser = null;

                    try {
                        existingUser = await UserManager.getById(user.uid);
                    } catch (e) {}

                    if (existingUser === null) {
                        existingUser = new User({id: user.uid, email: user.email, joinedAt: serverTimestamp()});
                        setAuthenticatedUser(existingUser);
                        openSnackbar('The user is not registered in the system. Please fill out the profile form.', 'warning');
                        history.push(RoutePath.PROFILE_EDIT);

                        try {
                            await UserManager.create(existingUser);
                        } catch (e) {
                            logSentryException(`${e.message} - ${user.uid} - ${user.email}`, {...user}, 'PROFILE_CREATION_ERROR');
                        }
                    } else {
                        setAuthenticatedUser(existingUser);

                        if (window.location.pathname === RoutePath.SIGN_IN) {
                            history.push(RoutePath.CAREER_VIEW_ALL);
                        }

                        openSnackbar('User logged in successfully.');
                    }
                } catch (e) {
                    openSnackbar(e.message, 'error');
                    logSentryException(e);
                } finally {
                    setLoading(false);
                }
            });
        } catch (e) {
            openSnackbar(e.message, 'error');
            logSentryException(e);
        } finally {
            setLoading(false);
        }
    }, []);

    const value = {
        currentUser,
        authenticatedUser,
        isAdmin,
        isAuthenticated,
        setAuthenticatedUser,
        login,
        loginWithGoogle,
        signup,
        logout,
        resetPassword,
        updateEmail,
        updatePassword,
    }

    return (
        <AuthContext.Provider value={value}>
            {!loading && authenticatedUser !== undefined && children}
        </AuthContext.Provider>
    )
}