import React from "react";
import {RoutePath} from "../../utils/enums.js";
import {Link, useLocation} from "react-router-dom";
import {MobileMenu} from "../nav/MobileMenu.js";

export default function IntroNavbar() {
    const location = useLocation();

    return (
        <span className="styles">
            <header className="header-1">
                <div className="container">
                    <div className="row align-items-center justify-content-between">
                        <div className="col-lg-2 col-sm-5 col-md-4 col-6">
                            <div className="logo">
                                <img src="/images/white-long-logo.png"
                                     alt="MC"
                                     style={{width: 124, height: 70, margin: 1}}/>
                            </div>
                        </div>
                        <div
                            className="col-lg-10 text-end p-lg-0 d-none d-lg-flex justify-content-end align-items-center">
                            <div className="menu-wrap">
                                <div className="main-menu">
                                    <ul>
                                        <li>
                                            <Link to={RoutePath.CANDIDATE_HOME}>
                                                <a style={{
                                                    lineHeight: 1.5,
                                                    verticalAlign: 'middle',
                                                    color: location.pathname === RoutePath.CANDIDATE_HOME ? '#FFD44B' : 'white',
                                                }}>FOR
                                                    CANDIDATES</a>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to={RoutePath.EMPLOYER_HOME}>
                                                <a style={{
                                                    lineHeight: 1.5,
                                                    verticalAlign: 'middle',
                                                    color: location.pathname === RoutePath.EMPLOYER_HOME ? '#FFD44B' : 'white',
                                                }}>FOR
                                                    EMPLOYERS</a>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to={RoutePath.SIGN_IN}>
                                                <button className="btn" style={{
                                                    backgroundColor: 'white',
                                                    color: location.pathname === RoutePath.EMPLOYER_HOME ? '#8B0E3A' : '#5C0E7B',
                                                    fontWeight: '500',
                                                    marginLeft: '50px',
                                                }}>SIGN IN
                                                </button>
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <MobileMenu/>
                    </div>
                </div>
            </header>
        </span>
    );
}
