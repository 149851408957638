import React, {useState} from 'react';
import {Button, Typography} from '@mui/material';
import {UserManager} from "../../managers/UserManager.js";
import {UploadedDocumentFile} from "./UploadedDocumentFile.js";
import {useSnackbar} from "../../contexts/SnackbarContext.js";
import {logSentryException} from "../../utils/utils.js";

export const CoverLetterSelector = ({selectedCoverLetter, setSelectedCoverLetter}) => {
    const [loading, setLoading] = useState(false);
    const {openSnackbar} = useSnackbar();

    const handleFileChange = async (event) => {
        try {
            setLoading(true);
            const selectedFile = event.target.files[0];
            const uploadedFile = await UserManager.uploadDocument(selectedFile, false);
            setSelectedCoverLetter(uploadedFile);
            openSnackbar('Cover letter uploaded successfully.');
        } catch (e) {
            openSnackbar('Failed to upload a file. ' + e.toString().replace('FirebaseError: Firebase:', ''), 'error');
            logSentryException(e);
        } finally {
            setLoading(false);
            event.target.value = null;
        }
    };

    return (
        <div style={{textAlign: 'center'}}>
            <Typography variant='h5' sx={{marginTop: 2, fontWeight: 'bold'}}>Cover Letter</Typography>
            {
                !selectedCoverLetter ?
                    <div style={{marginTop: 10}}>
                        <input
                            accept=".pdf,.doc,.docx"
                            style={{display: 'none'}}
                            id="cover-letter-upload"
                            type="file"
                            onChange={handleFileChange}
                            disabled={loading}
                        />
                        <label htmlFor="cover-letter-upload">
                            <Button component="span"
                                    variant="outlined"
                                    sx={{width: '100%'}}
                                    disabled={loading}>
                                Upload Cover Letter
                            </Button>
                        </label>
                        <Typography sx={{fontSize: '.8rem'}}>(optional - pdf - 2 MB max)</Typography>
                    </div> :
                    <Typography sx={{marginTop: 1}}>You can upload only 1 cover letter per job</Typography>
            }
            {
                selectedCoverLetter &&
                <UploadedDocumentFile key={`cover-letter`}
                                      file={selectedCoverLetter}
                                      onClick={() => null}
                                      onDelete={() => setSelectedCoverLetter(null)}/>
            }
        </div>
    );
}