import {ApplicationStatusStepper} from "./ApplicationStatusStepper";
import Card from "@mui/material/Card";
import React, {forwardRef} from "react";
import {Divider} from "@mui/material";
import {useHistory} from "react-router-dom";
import {RoutePath} from "../../utils/enums";
import {CareerCardContent} from "../career/CareerCardContent.js";

export const ApplicationWithCareerDetailsCard = forwardRef(({application}, ref) => {
    const history = useHistory();
    const career = application.career;

    return career ? (
        <Card ref={ref}
              sx={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            transition: 'background 0.2s ease-in-out',
            '&:hover': {
                background: '#F5F5F5',
            },
        }} onClick={() => history.push(RoutePath.CAREER_VIEW(application.careerId))}>
            <CareerCardContent career={career}>
                <ApplicationStatusStepper steps={application.statusHistory} isShortVersion={true}/>
                <Divider sx={{my: 1}}/>
            </CareerCardContent>
        </Card>
    ) : null
})